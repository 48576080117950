import type { Preferences } from "@segment/consent-manager/types/types";

/**
 * Function to determine if tracking is allowed based on preferences.
 * @param preferences The current preferences object from the consent manager
 * @returns {boolean} Whether tracking is allowed
 */
export const isTrackingAllowed = (preferences: Preferences): boolean => {
  return preferences?.customPreferences?.["Non-Essential"];
};
