import { gql } from "@apollo/client";
import { AddressValues } from "@components/account/address/Address.model";
import { Shopify } from "@models/shopify.model";
import { Dispatch, SetStateAction } from "react";

export enum ProfileAction {
  USER_LOGIN = "login",
  USER_LOGOUT = "logout",
  GET_USER = "get_user",
  REFRESH_TOKEN = "refresh_token",
  SET_VERIFICATION = "get_verification",
  UPDATE_AUTH_USER = "update_auth_user",
}

export enum ProfileEndpoints {
  CHECK_EMAIL = "/account/check_email/",
  VERIFY = "/account/verify/", // deprecated
  VERIFY_EMAIL = "/account/verify_email/",
  LOGIN = "/account/login_ecomm/",
  SET_PASSWORD = "/account/set_password/",
  FORGOT_PASSWORD = "/user/forgot_password/",
  RESET_PASSWORD = "/user/reset_password/",
  USER = "/user/me/",
  SUBSCRIPTION = "/stripe/subscription/",
  REACTIVATE_SUBSCRIPTION = "/stripe/reactivate/",
  REFERRAL = "/user/referral/",
  CHANGE_EMAIL = "/user/change_email/",
  CHANGE_PASSWORD = "/user/change_password/",
  VERIFY_PASSWORD = "/account/verify_password/",
  ACCOUNT_TRANSFER = "/account/transfer/",
  CHARGES = "/stripe/charge/",
  INVOICES = "/stripe/invoice/",
  GET_CREDIT_CARD = "/stripe/payment_methods/",
  SETUP_INTENTS = "/stripe/setup_intents/",
  ATTACH_PAYMENT = "/stripe/setup_intents/attach/",
  ADDRESS = "/user/address/",
  OPT_OUT = "/opt_out/",
}
// Can't mix computed properties into string enums :(
export const verifyEmailEndpoint = (email: string) => `/account/${email}/verify/`;

export enum AccountType {
  PARENT = "parent",
  CHILD = "child",
  SINGLE = "single",
  INVITEE = "invitee",
}

export enum CountryCodeType {
  US = "US",
  CA = "CA",
}

export enum AccountStatusType {
  NEEDS_VERIFICATION = "needs_verification",
  NEEDS_PASSWORD = "needs_password",
  FORGOT_PASSWORD = "forgot_password",
  SENT_VALIDATION = "sent_validation",
  PENDING_VALIDATION = "pending_validation",
  VALID = "valid",
}

export interface AuthStateUserProfile extends UserProfile {
  address: UserAddress;
  referral_code: string;
}

export interface AuthStateCheckoutResource {
  is_referral: boolean;
  referral_code: string | null;
  referral_email: string | null;
}

export interface AuthStateValue {
  user: AuthStateUserProfile;
  session: {
    id: string;
    timestamp: number;
    // These are snakecase to match what will be sent through segment events
    utm_campaign?: string;
    utm_medium?: string;
    utm_source?: string;
    utm_term?: string;
    [key: string]: any;
  };
  checkout: AuthStateCheckoutResource;
  external_id?: string;
  isLoading: boolean;
  error: any;
  flags: {
    isLoginTracked: boolean;
    wasLoginClicked: boolean;
  };
}

export interface AuthState {
  value: AuthStateValue;
  set: Dispatch<SetStateAction<AuthStateValue>>;
  delete: () => void;
}

export interface AuthToken {
  value: string;
  expires: Date | string;
  set: Dispatch<SetStateAction<string>>;
  delete: () => void;
}

export interface ShopifyToken {
  value: {
    access_token: string;
    expires_at: string;
  };
  set: Dispatch<SetStateAction<{ access_token: string; expires_at: string }>>;
  delete: () => void;
}

export interface AccountVerify {
  is_registered: boolean;
  is_verified: boolean;
  uuid: string;
  account_type: AccountType;
  signup_allowed: boolean;
  country_code: CountryCodeType.US;
  has_trialed: boolean;
  subscription_tier: number;
}

export enum SubscriptionTier {
  ALL_ACCESS = 0,
  APP_MONTHLY = 4,
  APP_ANNUAL = 5,
}

export enum SubscriptionType {
  ALL_ACCESS = "lululemon Studio All-Access ",
  APP_MONTHLY = "lululemon Studio App Monthly",
  APP_ANNUAL = "lululemon Studio App Annual",
}

export const subscriptionTypes = {
  [SubscriptionTier.ALL_ACCESS]: SubscriptionType.ALL_ACCESS,
  [SubscriptionTier.APP_MONTHLY]: SubscriptionType.APP_MONTHLY,
  [SubscriptionTier.APP_ANNUAL]: SubscriptionType.APP_ANNUAL,
};

export const digitalSubscriptions = [SubscriptionTier.APP_MONTHLY, SubscriptionTier.APP_ANNUAL];

export enum SubscriptionProvider {
  STRIPE = "stripe",
  APPLE = "apple",
  GOOGLE = "google",
}

export interface UserSubscription {
  level: SubscriptionTier;
  provider: SubscriptionProvider;
  is_active: boolean;
  cancel_at: string;
  expiration_reason: string;
  is_trialing: boolean;
}

export interface UserSubscriptionDetail {
  subscription_tier: SubscriptionTier;
  apple_transaction_token: string;
  google_transaction_token: string;
  subscription_history: Array<UserSubscription>;
}

export interface UserProfile extends UserSubscriptionDetail {
  id: number;
  uuid: string;
  name: string | null;
  public_name: string | null;
  email: string;
  is_child: boolean;
  is_parent: boolean;
  is_pending: boolean;
  is_internal: boolean;
  is_beta: boolean;
  is_using_devbox: boolean;
  is_1to1_enabled: boolean;
  is_commercial: boolean;
  is_inapp_content_enabled: boolean;
  is_offline_content_enabled: boolean;
  is_retail: boolean;
  subscription_active: boolean;
  subscription_tier: number | null;
  country_code: string;
  image: {
    url: string;
    image_type: string;
  };
  profile: {
    birthdate: string | null;
    location: string | null;
    phone_number: string | null;
    display_name: string | null;
    gender: string | null;
    is_private: boolean;
  };
  parent: any;
  children: Array<any>;
  workout_stats: {
    total: number;
    last_performed_at: string | null;
  };
  auto_renew: boolean;
}

export interface ProfileGlobalContext {
  isLoggedIn: boolean;
  isLoginOpen: boolean;
  setIsLoginOpen: Dispatch<React.SetStateAction<boolean>>;
  verification: UserVerification;
  setVerification: Dispatch<React.SetStateAction<UserVerification>>;
  profileState: {
    shopifyToken: ShopifyToken;
    authToken: AuthToken;
    profile: UserProfile;
    shopifyCustomer: Shopify.Customer;
    verificationStatus: AccountVerify;
    authState: AuthState;
  };
  dispatch: Dispatch<ProfileProviderDispatch>;
  verify: (verification_code: string) => Promise<any>;
  getAccount: (email: string, skip_verification: boolean) => Promise<AccountVerify>;
  checkEmail: (email: string) => Promise<any>;
  verifyCode: (code: string) => Promise<any>;
  login: ({
    email,
    password,
    dispatch,
    saveConsent,
  }: {
    email: string;
    password: string;
    dispatch: Dispatch<ProfileProviderDispatch>;
    saveConsent: Function;
  }) => Promise<void>;
  logout: (dispatch: Dispatch<ProfileProviderDispatch>, options?: { redirect?: boolean }) => void;
  getUser: (
    authToken: AuthToken,
    shopifyToken: ShopifyToken,
    dispatch: Dispatch<ProfileProviderDispatch>,
    onCustomerFetched: (customer: Shopify.Customer) => void
  ) => Promise<void>;
  updateProfile: (
    authToken: AuthToken,
    profile: UserProfile,
    dispatch: Dispatch<ProfileProviderDispatch>
  ) => Promise<void>;
  getSubscription: (authToken: AuthToken, email: string) => Promise<HardwareSubscription>;
  reactivateSubscription: (authToken: AuthToken) => Promise<HardwareSubscription>;
  getCreditCard: (authToken: AuthToken) => Promise<UserCard>;
  getReferral: (authToken: AuthToken) => Promise<UserReferral>;
  addReferralToAuth: (code: string, dispatch: Dispatch<ProfileProviderDispatch>) => Promise<void>;
  changeEmail: (email: string, authToken: AuthToken) => Promise<void>;
  changePassword: (
    password: string,
    password_confirmation: string,
    current_password: string,
    authToken: AuthToken
  ) => Promise<void>;
  forgotPassword: (email: string) => Promise<any>;
  resetPassword: (code: string, password: string, email: string) => Promise<any>;
  setPassword: (
    code: string,
    password: string,
    passwordConfirmation: string,
    dispatch: Dispatch<ProfileProviderDispatch>,
    saveConsent: Function
  ) => Promise<any>;
  verifyPassword: (password: string, authToken: AuthToken) => Promise<any>;
  createAccountTransfer: (
    transferee_email: string,
    reason: string,
    authToken: AuthToken
  ) => Promise<any>;
  getCharges: (authToken: AuthToken) => Promise<UserCharge[]>;
  getInvoices: (authToken: AuthToken) => Promise<UserInvoice[]>;
  setupPaymentIntents: (authToken: AuthToken) => Promise<{ client_secret: string }>;
  attachPaymentMethod: (authToken: AuthToken, payment_method_id: string) => Promise<any>;
  getAddress: (authToken: AuthToken) => Promise<UserAddress>;
  changeAddress: (authToken: AuthToken, address: AddressValues) => Promise<any>;
}

export interface ProfileProviderModel {
  shopifyToken?: ShopifyToken;
  authToken?: AuthToken;
  profile?: UserProfile;
  verificationStatus?: AccountVerify;
  authState?: AuthState;
}

export interface ProfileProviderDispatch extends ProfileProviderModel {
  type: ProfileAction;
  redirect?: boolean;
  shopifyCustomer?: Shopify.Customer;
  track?: {
    eventGroup: string;
    eventName: string;
  };
}

export interface ErrorResponse {
  error: boolean;
  response: {
    errors: {
      detail: string;
      error_code: string;
    };
  };
}

export interface UserReferral {
  title: string;
  image_id: string;
  image_url: string;
  subtitle_one: string;
  subtitle_two: string;
  code: string;
  terms_link: string;
  shareable_content: {
    title: string;
    subtitle: string;
    url: string;
    image_id: string;
    image_url: string;
  };
}

export interface HardwareSubscription extends Partial<ErrorResponse> {
  customer_id: string;
  subscription_id: string;
  subscription_status: SubscriptionStatus;
  subscription_activation_date: string;
  paused: boolean;
  credit: number;
  discount: {
    value: string;
    duration: string;
    frequency: number;
  };
  next_billing_amount: number;
  next_billing_date: string;
  final_billing_date: string;
  remaining_commitment_months: number;
  auto_renew: boolean;
  resumes_at?: string;
}

export enum SubscriptionStatus {
  Trialing = "trialing",
  Active = "active",
  Inactive = "inactive",
  PastDue = "past_due",
  Canceled = "canceled",
  Unpaid = "unpaid",
  Unknown = "unknown",
  Initial = "--",
}

export interface UserCharge {
  amount: number;
  currency: string;
  failure_code: string;
  failure_message: string;
  id: string;
  status: string;
}

export interface UserInvoice {
  created_at: string;
  currency: string;
  id: string;
  status: string;
  total: number;
}

export interface UserCard extends Partial<ErrorResponse> {
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
}

export interface UserAddress extends Partial<ErrorResponse> {
  name: string | null;
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  postal_code: string;
}

export const CUSTOMER_QUERY = gql`
  query Customer($accessToken: String!) {
    customer(customerAccessToken: $accessToken) {
      id
      firstName
      lastName
      acceptsMarketing
      email
      phone
      lastIncompleteCheckout {
        id
        lineItems(first: 100) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export interface UserVerification {
  code: string;
  email: string;
}
