export const FIXED_NAVBAR_HEIGHT = 120;

export const Color = {
  white: "#fff",

  black100: "#2b2b2b",
  black80: "#555",
  black60: "#7e7e7e",
  black40: "#d1d1d1",
  black20: "#f1f1f1",
  black10: "#f8f8f8",
  black: "#000",
  blackBrand: "#1a1a1a",

  grayLight3: "#dfdfdf",
  gray20: "#dbdbdb",
  gray: "#ababab",
  grayMedium: "#ebeaea",
  grayMedium2: "#8b8c8e",
  grayMedium3: "#969696",
  grayBrand: "#646567",
  grayInactive: "#9a9b9c",
  grayBorder: "#cecece",
  grayFill: "#f4f4f4",

  green100: "#5cb849",
  green80: "#7dc66d",
  green60: "#9dd492",
  green40: "#bee3b6",
  green20: "#def1db",
  greenAsh: "#b9c0bc",

  red100: "#ff3700",
  red80: "#ff5f33",
  red60: "#ff8766",
  red40: "#ffaf99",
  red20: "#ffd7cc",
  red10: "#c22e3a",
  redError: "#e51414",

  blueAsh: "#f2f7f9",
  goldAsh: "#e1e1e1",
  beigeHuman: "#f3f0eb",
};

export const Font = {
  bold: "'Calibre-Bold', sans-serif",
  practiceLightStencil: "'CalibrePractice-LightStencil', sans-serif",
  black: "'Calibre-Black', sans-serif",
  regular: "'Calibre-Regular', sans-serif",
  medium: "'Calibre-Medium', sans-serif",
  semibold: "'Calibre-Semibold', sans-serif",
  thin: "'Calibre-Thin', sans-serif",
  light: "'Calibre-Light', sans-serif",
};

export const Device = {
  small: "only screen and (max-width: 39.9375em)",

  // 640px
  mediumUp: "only screen and (min-width: 40em)",
  mediumUpLandscape: "only screen and (min-width: 40em) and (orientation: landscape)",
  mediumDown: "only screen and (max-width: 63.9375em)",
  medium: "only screen and (min-width: 40em) and (max-width: 63.9375em)",

  // 1024px
  largeUp: "only screen and (min-width: 64em)",
  large: "only screen and (min-width: 64em) and (max-width: 74.9375em)",

  // 1200px
  xlargeUp: "only screen and (min-width: 75em)",

  // 1440px
  "2xlargeUp": "only screen and (min-width: 90em)",

  // 1680px
  "3xlargeUp": "only screen and (min-width: 105em)",

  // 1920px
  "4xlargeUp": "only screen and (min-width: 120em)",
};

export const Ease = {
  out: `cubic-bezier(0.28, 0.44, 0.49, 1)`,
  outFast: `cubic-bezier(0.2, 1, 0.2, 1)`,
  duration: "0.2s",
};

export const EaseHeader = {
  curve: "cubic-bezier(0.5, 0, 0, 1)",
  duration: {
    transition: "0.65s",
    hover: "0.35s",
  },
};

export const EasePage = {
  curve: "cubic-bezier(0.5, 0, 0, 1)",
  duration: "1s",
};

export const Grid = {
  mobilePadding: "20px",
  mobilePaddingCols: "10px",
};

export const Spacing = {
  large: "140px",
  medium: "100px",
  small: "80px",
  xSmall: "60px",
  sectionMobile: 40,
  sectionDesktop: 70,
  contentPageLarge: 105,
  contentPageMedium: 65,
  contentPageSmall: 45,
};

export const Heights = {
  header: "60px",
  headerMobile: "60px",
  banner: "40px",
  bannerMobile: "60px",
};

export const Z = {
  header: "1049",
  subnav: "1050",
  cartOverlay: "1051",
};
