import { createGlobalStyle } from "styled-components";
import { Color, Device, Font } from "./constants";
import {
  HeadingDisplayCSS,
  Heading1CSS,
  Heading2CSS,
  Heading3CSS,
  Heading4CSS,
  FoundationHeadingOverridesCSS,
} from "./typography";
import { FoundationGridOverridesCSS } from "./grid";
import { rem } from "./helpers";

export const GlobalStyle = createGlobalStyle`
  :root {
    --header-height: 60px;
    --banner-height: 40px;
    --subnav-height: 60px;
    --compare-plans-container: ${rem(700)};
    --white: #ffffff;

    @media ${Device.mediumDown} {
      --banner-height: 60px;
    }
  }

  body {
    font-family: ${Font.regular};
    font-size: 16px;
    line-height: 1.25;
    color: ${Color.blackBrand};
    background-color: var(--white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: scroll;

    // stop background scrolling when modal is open
    // need to make this for mobile only!!!!
    &.modal-open {
      @media ${Device.small} {
        overflow: hidden;
        position: fixed;
      }
    }
  }

  ${FoundationHeadingOverridesCSS}

  .display {
    ${HeadingDisplayCSS}
  }
  
  h1, .h1 {
    ${Heading1CSS}
  }

  h2, .h2 {
    ${Heading2CSS}
  }

  h3, .h3 {
    ${Heading3CSS}
  }

  h4, .h4 {
    ${Heading4CSS}
  }

  svg {
    display: block;
  }

  iframe {
    border: 0;
  }

  address {
    font-style: normal;
  }

  a {
    color: ${Color.grayBrand};
    text-decoration: underline;

    &:focus,
    &:hover {
      color: ${Color.blackBrand};
    }
  }

  ${FoundationGridOverridesCSS}

  div[data-consent-manager-dialog] div {
    z-index: 1100
  }

  div.error,
  p.error,
  span.error {
    color: ${Color.redError};
  }

  a.affirm-as-low-as {
    text-decoration: none !important;
  }

  @media print {
    #bubbleheader1,
    #kustomer-ui-sdk-iframe {
      display: none !important;
    }
  }
`;
