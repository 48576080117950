import type {
  MarketingTokensProviderProps,
  MarketingTokens,
} from "./MarketingTokensProvider.model";

import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRouter } from "next/router";
import { useConsentManager } from "@providers/consent";
import { MARKETING_COOKIE } from "@utils/constants";
import { getFilteredTokens } from "@utils/getFilteredTokens";
import { isTrackingAllowed } from "@utils/segment/isTrackingAllowed";

// Context
const MarketingTokensContext = createContext<MarketingTokens>(null);

// Provider
const MarketingTokensProvider = ({ children }: MarketingTokensProviderProps) => {
  // Hooks
  const { query } = useRouter();
  const [cookies, setCookie, removeCookie] = useCookies([MARKETING_COOKIE]);
  const { preferences } = useConsentManager();

  // State
  const [marketingTokens, setMarketingTokens] = useState<MarketingTokens>(
    getFilteredTokens(cookies[MARKETING_COOKIE])
  );

  // Callbacks
  const setMarketingTokensCookie = useCallback(
    (tokens: MarketingTokens) => {
      const expiry = new Date(Date.now() + 2592000000);
      setCookie(MARKETING_COOKIE, tokens, { path: "/", expires: expiry });
    },
    [setCookie]
  );

  const onConsentRevoked = useCallback(() => {
    removeCookie(MARKETING_COOKIE);
    setMarketingTokens({});
  }, [removeCookie, setMarketingTokens]);

  // Effects
  useEffect(
    () => {
      if (!isTrackingAllowed(preferences)) {
        onConsentRevoked();
      } else {
        const cookieTokens = getFilteredTokens(cookies["mirror_marketingTokens"]);
        const queryTokens = getFilteredTokens(query);

        setMarketingTokens((prevMarketingTokens) => {
          const updatedMarketingTokens = {
            ...cookieTokens,
            ...prevMarketingTokens,
            ...queryTokens,
          };
          // Update cookie
          if (cookieTokens !== updatedMarketingTokens) {
            setMarketingTokensCookie(updatedMarketingTokens);
          }
          return updatedMarketingTokens;
        });
      }
    },
    // Only want to rerun if the query or preference changes, not interested in cookie changes since we set in here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onConsentRevoked, preferences, query, setMarketingTokensCookie]
  );

  return (
    <MarketingTokensContext.Provider value={marketingTokens}>
      {children}
    </MarketingTokensContext.Provider>
  );
};

// Context Hook
export const useMarketingTokens = () => {
  const result = useContext(MarketingTokensContext);
  if (!result) {
    throw new Error("Context used outside of its Provider!");
  }
  return result;
};

export default MarketingTokensProvider;
