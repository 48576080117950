import { gql } from "@apollo/client";
import { shopifyApolloClient } from "@clients/shopify-client";
import { Shopify } from "@models/shopify.model";
import { LocalStorageKeys } from "@models/storage.model";

export interface CheckoutAssociation {
  id?: Shopify.CheckoutId;
  shopifyAccessToken?: string;
}

export const associateCheckoutToUser = (association?: CheckoutAssociation) => {
  const checkoutId =
    association?.id ||
    JSON.parse(window.localStorage.getItem(LocalStorageKeys.SHOPIFY_CHECKOUT) as string);
  const { access_token: customerAccessToken } =
    association?.shopifyAccessToken ||
    JSON.parse(window.localStorage.getItem(LocalStorageKeys.SHOPIFY_TOKEN) as string);

  if (!checkoutId || !customerAccessToken) return;
  /**
   * After associating a checkout to a user, the checkout could be fetched again
   * in order to reflect the association mutation, but doing so doesn't serve a
   * purpose at this point in time.
   */
  shopifyApolloClient
    .mutate({
      mutation: ASSOCIATE_CUSTOMER_MUTATION,
      variables: {
        checkoutId,
        customerAccessToken,
      },
    })
    .catch((e) => {
      console.warn("Checkout could not be associated to user.");
      console.error(e);
    });
};

export const ASSOCIATE_CUSTOMER_MUTATION = gql`
  mutation associateCustomerWithCheckout($checkoutId: ID!, $customerAccessToken: String!) {
    checkoutCustomerAssociateV2(
      checkoutId: $checkoutId
      customerAccessToken: $customerAccessToken
    ) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
      customer {
        id
      }
    }
  }
`;
