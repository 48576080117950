import {
  ReactNode,
  createContext,
  useReducer,
  useContext,
  Dispatch,
  useEffect,
  useState,
} from "react";
import * as request from "superagent";
import * as qs from "query-string";
import { useLocalStorage } from "react-use";
import add from "date-fns/add";
import { AddressValues } from "@components/account/address/Address.model";
import {
  ProfileEndpoints,
  ProfileAction,
  verifyEmailEndpoint,
  ProfileProviderDispatch,
  ProfileGlobalContext,
  CUSTOMER_QUERY,
  AuthToken,
  ShopifyToken,
  UserVerification,
  AuthStateValue,
} from "./ProfileProvider.model";
import { shopifyApolloClient } from "@clients/shopify-client";
import { Shopify } from "@models/shopify.model";
import { handleResults } from "@utils/handleResults";
import { LocalStorageKeys } from "@models/storage.model";
import { associateCheckoutToUser } from "@utils/checkoutAssociation";
import { useShopifyCart } from "@providers/cart";
import * as queryStream from "query-string";
import { v4 as uuidv4 } from "uuid";
import { events, identify } from "@providers/analytics/analytics";
import { useMarketingTokens } from "@providers/marketing-tokens";
import { getFilteredTokens } from "@utils/getFilteredTokens";
import { apiBaseURL } from "@utils/apiHelpers";
import { HttpMethods, buildSignatureAuthHeaders } from "@services/requests";

const ProfileContext = createContext<ProfileGlobalContext | null>(null);

const formatAuthToken = (token: string) => {
  return {
    value: token,
    expires: add(Date.now(), {
      hours: parseInt(process.env.NEXT_PUBLIC_ECOMM_AUTH_EXPIRATION_PERIOD),
    }),
  };
};

async function verify(verification_code: string) {
  try {
    const {
      body: { data },
    } = await request.post(`${apiBaseURL()}${ProfileEndpoints.VERIFY}`).type("json").send({
      verification_code,
    });
    return data;
  } catch (e) {
    throw {
      error: true,
      response: e.response?.body,
    };
  }
}

async function getAccount(email: string, skip_verification: boolean) {
  try {
    const { authorization_header, http_signature_created } = buildSignatureAuthHeaders(
      HttpMethods.GET,
      `${apiBaseURL("v2.1")}${verifyEmailEndpoint(email)}`,
      {
        skip_verification_email: skip_verification,
      }
    );
    const {
      body: { data },
    } = await request
      .get(`${apiBaseURL("v2.1")}${verifyEmailEndpoint(email)}`)
      .set("Authorization", authorization_header)
      .set("Created", http_signature_created)
      .type("json")
      .query({
        skip_verification_email: skip_verification,
      });
    return data;
  } catch (e) {
    throw {
      error: true,
      response: e.response?.body,
    };
  }
}

async function checkEmail(email: string) {
  try {
    const {
      body: { data },
    } = await request.get(`${apiBaseURL()}${ProfileEndpoints.CHECK_EMAIL}`).type("json").query({
      email: email,
    });
    return data;
  } catch (e) {
    throw {
      error: true,
      response: e.response?.body,
    };
  }
}

async function verifyCode(code: string) {
  try {
    const {
      body: { data },
    } = await request
      .patch(`${apiBaseURL()}${ProfileEndpoints.VERIFY_EMAIL}`)
      .set("Authorization", `EcommBearerUnverified ${code}`);
    return data;
  } catch (e) {
    throw {
      error: true,
      response: e.response?.body,
    };
  }
}

async function login({
  email,
  password,
  dispatch,
  saveConsent,
}: {
  email: string;
  password: string;
  dispatch: Dispatch<ProfileProviderDispatch>;
  saveConsent: Function;
}) {
  const [
    {
      body: {
        data: { api_token, shopify_customer_token, opt_outs },
      },
    },
    verificationStatus,
  ] = await Promise.all([
    request.post(`${apiBaseURL()}${ProfileEndpoints.LOGIN}`).type("json").send({ email, password }),
    getAccount(email, true),
  ]);

  if (!shopify_customer_token || !api_token) {
    throw new Error("Unable to sign in.");
  }

  if (opt_outs?.length > 0) {
    saveConsent(false);
  }

  dispatch({
    type: ProfileAction.USER_LOGIN,
    shopifyToken: shopify_customer_token,
    authToken: api_token,
    verificationStatus,
  });
}

function logout(dispatch: Dispatch<ProfileProviderDispatch>, options?: { redirect?: boolean }) {
  dispatch({ type: ProfileAction.USER_LOGOUT, redirect: options?.redirect });
}

async function getUser(
  authToken: AuthToken,
  shopifyToken: ShopifyToken,
  dispatch: Dispatch<ProfileProviderDispatch>,
  onCustomerFetched: (customer: Shopify.Customer) => void
) {
  const results = await Promise.allSettled([
    request
      .get(`${apiBaseURL("v2.2")}${ProfileEndpoints.USER}`)
      .set("Authorization", `EcommBearer ${authToken.value}`),
    shopifyApolloClient.query<Shopify.Customer>({
      query: CUSTOMER_QUERY,
      variables: { accessToken: shopifyToken.value.access_token },
    }),
    request
      .get(`${apiBaseURL()}${ProfileEndpoints.REFERRAL}`)
      .set("Authorization", `EcommBearer ${authToken.value}`),
  ]);

  try {
    const [
      user,
      {
        data: { customer },
      },
      {
        body: { data },
      },
    ] = handleResults(results, dispatch);

    onCustomerFetched(customer);

    dispatch({
      type: ProfileAction.GET_USER,
      profile: { ...user.body.data, referral_code: data.code },
      shopifyCustomer: customer as Shopify.Customer,
    });
  } catch (err) {
    if (Array.isArray(err)) {
      err.forEach((e) => {
        console.error(e.reason);
      });
      return;
    }

    console.error(err);
  }
}

async function updateProfile(authToken, profile, dispatch) {
  try {
    const {
      body: { data },
    } = await request
      .patch(`${apiBaseURL("v2.2")}${ProfileEndpoints.USER}`)
      .set("Authorization", `EcommBearer ${authToken.value}`)
      .send({ ...profile });

    dispatch({
      type: ProfileAction.GET_USER,
      profile: { ...data },
      track: {
        eventGroup: "account",
        eventName: "updated",
      },
    });

    return data;
  } catch (e) {
    throw {
      error: true,
      response: e.response.body,
    };
  }
}

async function getSubscription(authToken: AuthToken, email: string) {
  try {
    const {
      body: { data },
    } = await request
      .get(`${apiBaseURL()}${ProfileEndpoints.SUBSCRIPTION}`)
      .set("Authorization", `EcommBearer ${authToken.value}`)
      .query({
        include_all: true,
        customer_email: email,
      });

    return data;
  } catch (e) {
    return {
      error: true,
      response: e.response.body,
    };
  }
}

async function reactivateSubscription(authToken: AuthToken) {
  try {
    const {
      body: { data },
    } = await request
      .post(`${apiBaseURL()}${ProfileEndpoints.REACTIVATE_SUBSCRIPTION}`)
      .set("Authorization", `EcommBearer ${authToken.value}`);

    return data;
  } catch (e) {
    throw {
      error: true,
      response: e.response.body,
    };
  }
}

async function getReferral(authToken: AuthToken) {
  try {
    const {
      body: { data },
    } = await request
      .get(`${apiBaseURL()}${ProfileEndpoints.REFERRAL}`)
      .set("Authorization", `EcommBearer ${authToken.value}`);

    return data;
  } catch (e) {
    return {
      error: true,
      response: e.response.body,
    };
  }
}

async function getReferralData(code: string) {
  try {
    const {
      body: { data },
    } = await request.get(`${apiBaseURL()}s/user/referral/${code}/`);

    return data;
  } catch (e) {
    throw {
      error: true,
      response: e.response?.body,
    };
  }
}

async function changeEmail(email: string, authToken: AuthToken) {
  try {
    const {
      body: { data },
    } = await request
      .post(`${apiBaseURL()}${ProfileEndpoints.CHANGE_EMAIL}`)
      .set("Authorization", `EcommBearer ${authToken.value}`)
      .send({ email });
    return data;
  } catch (e) {
    throw {
      error: true,
      response: e.response.body,
    };
  }
}

async function changePassword(
  password: string,
  password_confirmation: string,
  current_password: string,
  authToken: AuthToken
) {
  try {
    const {
      body: { data },
    } = await request
      .put(`${apiBaseURL()}${ProfileEndpoints.CHANGE_PASSWORD}`)
      .set("Authorization", `EcommBearer ${authToken.value}`)
      .send({
        password: password,
        password2: password_confirmation,
        current_password: current_password,
      });
    return data;
  } catch (e) {
    throw {
      error: true,
      response: e.response?.body,
    };
  }
}

async function forgotPassword(email: string) {
  try {
    const reqData = await request
      .post(`${apiBaseURL()}${ProfileEndpoints.FORGOT_PASSWORD}`)
      .send({ email });
    return reqData;
  } catch (err) {
    console.error(err);
  }
}

async function resetPassword(code: string, password: string, email: string) {
  try {
    const reqData = await request
      .put(`${apiBaseURL()}${ProfileEndpoints.RESET_PASSWORD}`)
      .send({ code, password, email });
    return reqData;
  } catch (err) {
    console.error(err);
  }
}

async function setPassword(
  code: string,
  password: string,
  passwordConfirmation: string,
  dispatch: Dispatch<ProfileProviderDispatch>,
  saveConsent: Function
) {
  try {
    const {
      body: {
        data: { api_token, shopify_customer_token, opt_outs },
      },
    } = await request
      .patch(`${apiBaseURL()}${ProfileEndpoints.SET_PASSWORD}`)
      .set("Authorization", `EcommBearerUnverified ${code}`)
      .send({ password: password, password2: passwordConfirmation });

    if (!shopify_customer_token || !api_token) {
      throw new Error("Unable to sign in.");
    }

    if (opt_outs?.length > 0) {
      saveConsent(false);
    }

    dispatch({
      type: ProfileAction.USER_LOGIN,
      shopifyToken: shopify_customer_token,
      authToken: api_token,
    });
  } catch (err) {
    throw {
      error: true,
      response: err.response.body,
    };
  }
}

async function verifyPassword(password: string, authToken: AuthToken) {
  try {
    const resp = await request
      .post(`${apiBaseURL()}${ProfileEndpoints.VERIFY_PASSWORD}`)
      .set("Authorization", `EcommBearer ${authToken.value}`)
      .send({ password });
    return resp;
  } catch (e) {
    return {
      error: true,
      response: e.response.body,
    };
  }
}

async function createAccountTransfer(
  transferee_email: string,
  reason: string,
  authToken: AuthToken
) {
  try {
    const resp = await request
      .post(`${apiBaseURL()}${ProfileEndpoints.ACCOUNT_TRANSFER}`)
      .set("Authorization", `EcommBearer ${authToken.value}`)
      .send({ transferee_email, reason });
    return resp;
  } catch (e) {
    return {
      error: true,
      response: e.response.body,
    };
  }
}

async function getCharges(authToken: AuthToken) {
  try {
    const {
      body: { data },
    } = await request
      .get(`${apiBaseURL()}${ProfileEndpoints.CHARGES}`)
      .set("Authorization", `EcommBearer ${authToken.value}`)
      .query({
        limit: 50,
        offset: null,
      });

    return data;
  } catch (e) {
    return {
      error: true,
      response: e.response.body,
    };
  }
}

async function getInvoices(authToken: AuthToken) {
  try {
    const {
      body: { data },
    } = await request
      .get(`${apiBaseURL()}${ProfileEndpoints.INVOICES}`)
      .set("Authorization", `EcommBearer ${authToken.value}`)
      .query({
        limit: 50,
        offset: null,
      });

    return data;
  } catch (e) {
    return {
      error: true,
      response: e.response.body,
    };
  }
}

async function getCreditCard(authToken: AuthToken) {
  try {
    const {
      body: { data },
    } = await request
      .get(`${apiBaseURL()}${ProfileEndpoints.GET_CREDIT_CARD}`)
      .set("Authorization", `EcommBearer ${authToken.value}`);

    return data;
  } catch (e) {
    return {
      error: true,
      response: e.response.body,
    };
  }
}

async function setupPaymentIntents(authToken: AuthToken) {
  try {
    const {
      body: { data },
    } = await request
      .post(`${apiBaseURL()}${ProfileEndpoints.SETUP_INTENTS}`)
      .set("Authorization", `EcommBearer ${authToken.value}`);

    return data;
  } catch (e) {
    throw {
      error: true,
      response: e.response.body,
    };
  }
}

async function attachPaymentMethod(authToken: AuthToken, payment_method_id: string) {
  try {
    const {
      body: { data },
    } = await request
      .post(`${apiBaseURL()}${ProfileEndpoints.ATTACH_PAYMENT}`)
      .set("Authorization", `EcommBearer ${authToken.value}`)
      .send({ payment_method_id });

    return data;
  } catch (e) {
    throw {
      error: true,
      response: e.response.body,
    };
  }
}

async function getAddress(authToken: AuthToken) {
  try {
    const {
      body: { data },
    } = await request
      .get(`${apiBaseURL()}${ProfileEndpoints.ADDRESS}`)
      .set("Authorization", `EcommBearer ${authToken.value}`);

    return data;
  } catch (e) {
    return {
      error: true,
      response: e.response.body,
    };
  }
}

async function changeAddress(authToken: AuthToken, address: AddressValues) {
  try {
    const {
      body: { data },
    } = await request
      .put(`${apiBaseURL()}${ProfileEndpoints.ADDRESS}`)
      .set("Authorization", `EcommBearer ${authToken.value}`)
      .send({ ...address });

    return data;
  } catch (e) {
    throw {
      error: true,
      response: e.response?.body,
    };
  }
}

async function addReferralToAuth(code: string, dispatch: Dispatch<ProfileProviderDispatch>) {
  const {
    profileState: { authState },
  } = useUserProfile();
  const referralData = await getReferralData(code);
  if (referralData.includes("email")) {
    dispatch({
      type: ProfileAction.UPDATE_AUTH_USER,
      authState: {
        ...authState,
        value: {
          ...authState.value,
          checkout: {
            is_referral: true,
            referral_code: code,
            referral_email: referralData["email"],
          },
        },
      },
    });
  }
}

function trackEvent(action, nextAuthState: AuthStateValue) {
  if (action.track) {
    identify(nextAuthState);
    const { eventGroup, eventName } = action.track;
    events[eventGroup][eventName](nextAuthState);
  }
}

function getInitialAuthState(marketingTokens = {}) {
  const initState = {
    user: null,
    isLoading: false,
    session: {
      id: uuidv4(),
      timestamp: new Date().getTime(),
      ...marketingTokens,
      ...queryStream.parse(location.search),
    },
    checkout: {
      is_referral: false,
      referral_code: null,
      referral_email: null,
    },
    flags: {
      wasLoginClicked: false,
      isLoginTracked: false,
    },
    error: null,
    external_id: uuidv4(),
  };
  return initState;
}

function getNextAuthState(state, action) {
  let nextAuthState;

  if (state?.authState?.value && action.profile) {
    nextAuthState = {
      ...state.authState.value,
      user: {
        ...state.authState.value.user,
        ...action.authState?.value?.user,
        ...action.profile,
      },
      session: {
        ...state.authState.value.session,
        id: action.profile.uuid,
      },
      checkout: {
        ...state.authState.value.checkout,
        ...action.authState?.value?.checkout,
      },
      flags: {
        ...state.authState.value.flags,
        ...action.authState?.value?.flags,
      },
    };
  } else if (action?.authState?.value?.session) {
    const tokens = getFilteredTokens(action.authState.value.session);
    nextAuthState = getInitialAuthState(tokens);
  } else {
    nextAuthState = getInitialAuthState();
  }

  return nextAuthState;
}

function profileReducer(state, action) {
  switch (action.type) {
    case ProfileAction.USER_LOGIN: {
      const authToken = formatAuthToken(action.authToken);

      state.shopifyToken.set(action.shopifyToken);
      state.authToken.set(authToken);
      associateCheckoutToUser({ shopifyAccessToken: action.shopifyToken.access_token });

      return {
        ...state,
        authToken: {
          ...state.authToken,
          ...authToken,
        },
        shopifyToken: {
          ...state.shopifyToken,
          value: action.shopifyToken,
        },
        verificationStatus: {
          ...state.verificationStatus,
          ...action.verificationStatus,
        },
      };
    }

    case ProfileAction.USER_LOGOUT: {
      // Segment Event must fire before we clear state
      events.account.logout(state.authState.value);

      state.shopifyToken.delete();
      state.authToken.delete();
      state.authState.delete();
      localStorage.removeItem(LocalStorageKeys.SHOPIFY_CHECKOUT);

      /**
       *
       * Router is intentionally avoided here since a
       * full page reload is used to reset logged in
       * UI.
       */
      if (action.redirect) {
        window.location.pathname = "/";
      }

      return {
        ...state,
        shopifyToken: {
          ...state.shopifyToken,
          value: null,
        },
        authToken: {
          ...state.authToken,
          value: null,
          expires: "",
        },
      };
    }

    case ProfileAction.GET_USER: {
      const nextAuthState = getNextAuthState(state, action);
      state.authState.set(nextAuthState);

      const nextState = {
        ...state,
        profile: { ...action.profile },
        authState: {
          ...state.authState,
          value: nextAuthState,
        },
        shopifyCustomer: action.shopifyCustomer,
      };

      trackEvent(action, nextAuthState);

      return nextState;
    }

    case ProfileAction.REFRESH_TOKEN: {
      const nextAuthState = getNextAuthState(state, action);
      const nextState = {
        ...state,
        authState: {
          ...state.authState,
          value: nextAuthState,
        },
      };

      if (action.authToken) {
        state.authToken.set(action.authToken);

        nextState.authToken = {
          ...state.authToken,
          value: action.authToken,
        };
      }

      return nextState;
    }

    case ProfileAction.SET_VERIFICATION: {
      return {
        ...state,
        verificationStatus: {
          ...action.verificationStatus,
        },
      };
    }

    case ProfileAction.UPDATE_AUTH_USER: {
      const nextAuthState = getNextAuthState(state, action);
      state.authState.set(nextAuthState);

      const nextState = {
        ...state,
        authState: {
          ...state.authState,
          value: nextAuthState,
        },
      };
      trackEvent(action, nextAuthState);

      return nextState;
    }

    default: {
      console.error(`Action of type ${action.type} does not exist within ProfileProvider.`);
      return { ...state };
    }
  }
}

export const ProfileProvider = ({ children }: { children?: ReactNode }) => {
  const [mounted, setMounted] = useState(false);

  const [shopifyToken, setShopifyToken, removeShopifyToken] = useLocalStorage(
    LocalStorageKeys.SHOPIFY_TOKEN
  );
  const [authToken, setAuthToken, removeAuthToken] = useLocalStorage<AuthToken>(
    LocalStorageKeys.AUTH_TOKEN
  );
  const marketingTokens = useMarketingTokens();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [verification, setVerification] = useState<UserVerification>({
    code: "",
    email: "",
  });
  const { onCustomerFetched } = useShopifyCart();
  const [authState, setAuthState, removeAuthState] = useLocalStorage(LocalStorageKeys.AUTH_STATE);

  useEffect(() => {
    setMounted(true);
  }, []);

  const [profileState, dispatch] = useReducer(profileReducer, {
    profile: {},
    authState: {
      value: authState,
      set: setAuthState,
      delete: removeAuthState,
    },
    shopifyCustomer: {},
    shopifyToken: {
      value: shopifyToken,
      set: setShopifyToken,
      delete: removeShopifyToken,
    },
    authToken: {
      ...(authToken || { value: null, expires: "" }),
      set: setAuthToken,
      delete: removeAuthToken,
    },
    verificationStatus: {},
  });
  /**
   * Checks if user has a token.
   *
   */
  useEffect(() => {
    const { value } = profileState?.authToken;
    setIsLoggedIn(value && !isLoggedIn);
  }, [profileState.authToken.value]);

  useEffect(() => {
    const flags = profileState.authState.value?.flags;
    if (
      flags?.wasLoginClicked &&
      !flags?.isLoginTracked &&
      profileState.authState.value?.user?.email
    ) {
      identify(profileState.authState.value, { email: profileState.authState.value.user.email });
      events.account.login(profileState.authState.value);
      dispatch({
        type: ProfileAction.UPDATE_AUTH_USER,
        profile: profileState.profile,
        authState: {
          ...profileState.authState,
          value: {
            ...profileState.authState.value,
            flags: {
              ...profileState.authState.value.flags,
              isLoginTracked: true,
            },
          },
        },
      });
    }
  }, [profileState.authState.value?.flags]);

  useEffect(() => {
    if (!profileState.authState.value) {
      const initState = getInitialAuthState(marketingTokens);
      setAuthState(initState);
      dispatch({
        type: ProfileAction.UPDATE_AUTH_USER,
        profile: profileState.profile,
        authState: {
          ...profileState.authState,
          value: {
            ...initState,
          },
        },
      });
    }
  }, [mounted]);

  // Fetch user when user is logged in
  useEffect(() => {
    const fetchUser = async () => {
      await getUser(
        profileState?.authToken,
        profileState?.shopifyToken,
        dispatch,
        onCustomerFetched
      );
    };
    const fetchAddress = async () => {
      const address = await getAddress(profileState?.authToken);
      if (address) {
        const authState = profileState?.authState;
        dispatch({
          type: ProfileAction.UPDATE_AUTH_USER,
          profile: profileState?.profile,
          authState: {
            ...authState,
            value: {
              ...authState?.value,
              user: {
                ...authState?.value?.user,
                address: address,
              },
            },
          },
        });
      }
    };

    // Only fetch if the user is logged in and the profile hasn't been fetched yet.
    if (profileState?.authToken?.value && !profileState?.profile?.email) {
      fetchUser();
      fetchAddress();
    }
  }, [isLoggedIn]);

  const value = {
    isLoggedIn,
    isLoginOpen,
    setIsLoginOpen,
    verification,
    setVerification,
    profileState,
    dispatch,
    verify,
    getAccount,
    checkEmail,
    verifyCode,
    login,
    logout,
    getUser,
    updateProfile,
    getSubscription,
    reactivateSubscription,
    getReferral,
    addReferralToAuth,
    changeEmail,
    changePassword,
    forgotPassword,
    resetPassword,
    setPassword,
    verifyPassword,
    createAccountTransfer,
    getCharges,
    getInvoices,
    getCreditCard,
    setupPaymentIntents,
    attachPaymentMethod,
    getAddress,
    changeAddress,
  };

  if (!mounted) {
    return null;
  }

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export const useUserProfile = () => {
  const context = useContext(ProfileContext);

  if (context == undefined) {
    throw new Error("useUserProfile must be used within a ProfileProvider.");
  }
  return context;
};

export default ProfileProvider;
