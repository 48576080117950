import type {
  MarketingCookies,
  MarketingTokens,
} from "@providers/marketing-tokens/MarketingTokensProvider.model";
import { ValidTokens } from "@providers/marketing-tokens/MarketingTokensProvider.model";
import type { ParsedUrlQuery } from "querystring";

/**
 * Function to get all valid tokens from the query or cookie and return an object containing them.
 * Only returns defined token values, undefined tokens will be skipped.
 * @param {ParsedUrlQuery | any} tokenObject
 *   Either the query object from the nextjs router or the cookie object from react-cookies.
 * @returns {MarketingTokens} Object containing all defined tokens for the query string.
 */
export const getFilteredTokens = (
  tokenObject: ParsedUrlQuery | MarketingCookies
): MarketingTokens => {
  const filteredTokens: MarketingTokens = {};

  if (tokenObject) {
    for (const token in ValidTokens) {
      if (tokenObject[token] != undefined) {
        filteredTokens[token] = tokenObject[token];
      }
    }
  }
  return filteredTokens;
};
