import type { ConsentManagerBuilderRenderCallbackProps } from "../types/ConsentManagerBuilder.types";

import { ReactNode } from "react";
import { ConsentManagerBuilder } from "@segment/consent-manager";
import inEU from "@segment/in-eu";

import ConsentManagerProvider from "@providers/consent";

import { isBrowserProtected } from "@utils/isBrowserProtected";
import {
  segmentInitialPreferences,
  segmentCustomCategories,
  segmentMapCustomPreferences,
} from "../segmentSettings";

const createCMBRenderCallback = (
  children: ReactNode
): ((props: ConsentManagerBuilderRenderCallbackProps) => React.ReactElement) => {
  // Can't return anonymous function or displayName won't be set
  const CMBRenderCallback = ({
    destinationPreferences,
    destinations,
    isConsentRequired,
    preferences,
    saveConsent,
  }: ConsentManagerBuilderRenderCallbackProps) => {
    //if globalPrivacyControl is true, the user doesn't want to be tracked
    if (isBrowserProtected()) {
      saveConsent(false);
    }

    return (
      <ConsentManagerProvider
        destinationPreferences={destinationPreferences}
        destinations={destinations}
        isConsentRequired={isConsentRequired}
        preferences={preferences}
        saveConsent={saveConsent}>
        {children}
      </ConsentManagerProvider>
    );
  };
  return CMBRenderCallback;
};

const SegmentServices = ({ children }: { children?: ReactNode }) => {
  const writeKey = process.env.NEXT_PUBLIC_SEGMENT_API_KEY;

  return (
    <ConsentManagerBuilder
      customCategories={segmentCustomCategories}
      initialPreferences={segmentInitialPreferences}
      mapCustomPreferences={segmentMapCustomPreferences}
      shouldReload={true}
      shouldRequireConsent={inEU}
      writeKey={writeKey}>
      {createCMBRenderCallback(children)}
    </ConsentManagerBuilder>
  );
};

export default SegmentServices;
