import type {
  ConsentManagerProviderProps,
  ConsentManagerValues,
} from "./ConsentManagerProvider.model";

import { createContext, useContext, useState } from "react";
import { useCookies } from "react-cookie";

import { CONSENT_BANNER_COOKIE } from "@utils/constants";

const ConsentManagerContext = createContext<ConsentManagerValues>(null);

const ConsentManagerProvider = ({
  children,
  destinationPreferences,
  destinations,
  isConsentRequired,
  preferences,
  saveConsent,
}: ConsentManagerProviderProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies([CONSENT_BANNER_COOKIE]);

  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);
  const bannerVisited = () => setCookie(CONSENT_BANNER_COOKIE, true);

  const value = {
    destinations,
    saveConsent,
    isModalOpen,
    openModal,
    closeModal,
    bannerVisited,
    preferences: { destinationPreferences, customPreferences: preferences },
    showBanner: isConsentRequired && !cookies["consent-banner-visited"],
  };

  return <ConsentManagerContext.Provider value={value}>{children}</ConsentManagerContext.Provider>;
};

export const useConsentManager = () => {
  const context = useContext(ConsentManagerContext);
  if (context == undefined) {
    throw new Error("Context used outside of its Provider!");
  }
  return context;
};

export default ConsentManagerProvider;
