import { Shopify } from "@models/shopify.model";
/**
 *
 * @desc Extract variants from their Product edges.
 * @param {Shopify.Product}.storefrontProduct A Product fetched from the Storefront API by the ProductProvier's PRODUCT_QUERY.
 * @returns {Shopify.ProductVariant[]} Array of ProductVariants.
 */
export const getVariantNodes = (storefrontProduct: Shopify.Product) => {
  return storefrontProduct?.variants?.edges.map((edge) => edge.node).flat() || [];
};
