import * as CryptoJS from "crypto-js";
import { isEmptyObj } from "@utils/isEmptyObj";

enum HttpMethods {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
}

function buildSignatureAuthHeaders(method: HttpMethods, url, search_params={}) {
  // build the unhashed string in httpsig format
  function buildSignatureBaseString(args) {
    var res = Object.keys(args).map((key, i) => `${key}: ${args[key]}`);
    return res.join("\n");
  }

  function hmacHash(data, secret) {
    var hash = CryptoJS.HmacSHA256(data, secret);
    return hash.toString(CryptoJS.enc.Base64);
  }

  const url_obj = new URL(url);
  let url_full = url_obj.pathname
  if (!isEmptyObj(search_params)){
    const searchParams = new URLSearchParams(search_params);
    url_full = `${url_full}?${searchParams.toString()}`
  }
  const timestamp = Math.floor(Date.now() / 1000);
  const site_url = new URL(process.env.NEXT_PUBLIC_MIRROR_API_BASE_URL);
  const header = {
    "(request-target)": `${method.toLowerCase()} ${url_full}`,
    created: timestamp,
    host: site_url.hostname,
  };

  const baseString = buildSignatureBaseString(header);
  const signature = hmacHash(baseString, process.env.NEXT_PUBLIC_MIRROR_APP_SECRET);

  return {
    authorization_header: `Signature keyId="${process.env.NEXT_PUBLIC_MIRROR_APP_KEY}\",algorithm=\"hmac-sha256\",signature=\"${signature}\",headers=\"(request-target) created host\"`,
    http_signature_created: header["created"],
  };
}

export { HttpMethods, buildSignatureAuthHeaders };
