import { css } from "styled-components";

export const AbsoluteCoverCSS = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const AbsoluteCenterCSS = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const AbsoluteCenterXCSS = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
