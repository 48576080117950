import { useRef, useEffect } from "react";
import { useRouter } from "next/router";
import { FIXED_NAVBAR_HEIGHT } from "@styles/constants";
import getRootOffset from "@utils/getRootOffset";

/**
 * @desc Awaits scrolling to anchors until they are rendered.
 * @returns null
 */
const AnchorScroll = (): null => {
  const { isReady, asPath } = useRouter();
  const hash = asPath.slice(asPath.indexOf("#"));
  const initialKey = useRef(isReady);
  const loop = useRef<number>(0);

  useEffect(() => {
    if (!hash) return;

    if ("fonts" in document) {
      document.fonts.ready.then(() => {
        setTimeout(() => scrollToSectionHandler(), 1000);
      });
    } else {
      setTimeout(() => scrollToSectionHandler(), 1000);
    }

    return () => {
      cancelAnimationFrame(loop.current);
    };
  }, []);

  /**
   * If there is a pending scroll and the location changes, cancel it.
   */
  useEffect(() => {
    if (isReady !== initialKey.current && loop.current) {
      cancelAnimationFrame(loop.current);
    }
  }, [isReady]);

  const scrollToSectionHandler = () => {
    const element = document.getElementById(hash.replace("#", ""));
    if (!element) {
      loop.current = requestAnimationFrame(scrollToSectionHandler);
      return;
    }

    const bounds = getRootOffset(element);
    const yOffset = bounds.top - FIXED_NAVBAR_HEIGHT;
    window.scrollTo({
      top: yOffset,
      behavior: "smooth",
    });
    cancelAnimationFrame(loop.current);
  };

  return null;
};

export default AnchorScroll;
