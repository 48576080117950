const getRootOffset = (el?: HTMLElement, root = window): { top: number; left: number } => {
  let parent: any = el
  const bounds = {
    top: 0,
    left: 0,
  }

  if (!el) return bounds

  do {
    if (!isNaN(parent.offsetTop)) {
      bounds.top += parent.offsetTop
    }
    if (!isNaN(parent.offsetLeft)) {
      bounds.left += parent.offsetLeft
    }
    parent = parent.offsetParent
  } while (parent && parent !== root)

  return bounds
}

export default getRootOffset
