import { createContext, useReducer, useContext, ReactNode, Dispatch } from "react";
import { shopifyApolloClient } from "@clients/shopify-client";
import {
  ProductAction,
  ShopifyProductDispatch,
  ShopifyProductProviderState,
  PRODUCT_QUERY,
  ProductProviderContext,
} from "./ProductProvider.model";
import { Shopify } from "@models/shopify.model";

const ProductContext = createContext<ProductProviderContext>(null);

async function getShopifyProduct(dispatch: Dispatch<ShopifyProductDispatch>, handle: string) {
  try {
    const {
      data: { product },
    } = await shopifyApolloClient.query({ query: PRODUCT_QUERY, variables: { handle } });

    const castProduct = product as Shopify.Product;

    if (!castProduct) throw new Error("Product could not be fetched.");
    dispatch({ type: ProductAction.SET_PRODUCT, product: castProduct });
    return castProduct;
  } catch (e) {
    console.error(e);
    dispatch({ type: ProductAction.FAILED_FETCH, message: e });
  }
}

function productReducer(state: ShopifyProductProviderState, action: ShopifyProductDispatch) {
  switch (action.type) {
    case ProductAction.SET_PRODUCT: {
      return {
        products: {
          ...state.products,
          [action.product.handle]: action.product,
        },
      };
    }

    case ProductAction.FAILED_FETCH: {
      console.warn("Failed to update context with product.");
      return {
        ...state,
      };
    }

    default: {
      console.error(`Action of type ${action.type} does not exist within ProductProvider.`);
      return { ...state };
    }
  }
}

export const ShopifyProductProvider = ({ children }: { children?: ReactNode }) => {
  const [state, dispatch] = useReducer(productReducer, {
    products: {},
  });

  const value = {
    state,
    dispatch,
    getShopifyProduct,
  };

  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};

export const useShopifyProduct = () => {
  const context = useContext(ProductContext);

  if (context == undefined) {
    throw new Error("useShopifyProduct must be used within a ShopifyProductProvider.");
  }
  return context;
};

export default ShopifyProductProvider;
