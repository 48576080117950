export const AFFIRM_MINIMUM = 50;
export const AFFIRM_MAXIMUM = 17500;
export const APP_STORE_URL =
  "https://itunes.apple.com/us/app/mirror-workout-companion/id1153358600";
export const BLOG_URL = "https://mirror.co/reflections/";
export const CANADA_SITE = "https://mirrorcanada.com";
export const CAL_TRANSPARENCY_ACT_URL = "https://info.lululemon.com/about/modern-slavery-statement";
export const CAREERS_URL = "https://jobs.lever.co/mirror";
export const COMPANY_DISPLAY_NAME = "MIRROR Inc.";
export const COMPANY_NAME = "Curiouser Products Inc.";
export const COVID_FAQ_URL = "https://mirror.kustomer.help/en_us/an-update-on-covid-19-r1DqHu6rL";
export const FAQ_URL = "https://mirror.kustomer.help";
export const BUSINESS_URL = "https://mirror.kustomer.help/en_us/mirror-for-businesses-H1AhJpc9_";
export const DELIVERY_STATUS_URL = "https://mirror.kustomer.help/en_us/delivery-status-H1v7pNdMu";
export const GOOGLE_STORE_URL = "https://play.google.com/store/apps/details?id=co.mirror.android";
export const REFERRAL_TERMS_URL = "https://referral.mirror.co/terms/";
export const REFERRAL_TERMS_URL_CA = "http://referral.mirror.co/canadaterms/";
export const PASSWORD_MAX_LENGTH = 26;
export const PASSWORD_MIN_LENGTH = 6;
export const PHONE_NUMBER_FORMATTED = "+1 (888) 288-1588";
export const PHONE_NUMBER = PHONE_NUMBER_FORMATTED.replace(/[^\d]+/g, "");
export const PHONE_NUMBER_FORMATTED_CA = "+1 (855) 793-2805";
export const PHONE_NUMBER_CA = PHONE_NUMBER_FORMATTED_CA.replace(/[^\d]+/g, "");
export const SESSION_EXPIRATION = 30; // Days
export const SUBSCRIPTION_TERMS_URL = "https://www.mirror.co/terms";
export const SUPPORT_EMAIL = "hello@lululemonstudio.com";
export const SUPPORT_EMAIL_CA = "hellocanada@mirror.co";
export const US_SITE = "https://www.mirror.co";
export const US_ID = 232;
export const CA_ID = 38;
export const MULBERRY_PRODUCT_SLUG = "mulberry-warranty";
export const APPLICATION_NODE = "__next";
export const ZERO = "$0.00";
export const NO_VALUE = `--`;
export const EMAIL_REGEX =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const MARKETING_COOKIE = "mirror_marketingTokens";
export const CONSENT_BANNER_COOKIE = "consent-banner-visited";
export const SEGMENT_ANONYMOUS_ID_COOKIE = "ajs_anonymous_id";
