import { Shopify } from "@models/shopify.model";
import { LocalLineItem } from "@models/storage.model";
import { AuthState } from "@providers/profile";
import React from "react";

export interface AppliedGiftCard {
  amountUsed: Shopify.MoneyV2;
  balance: Shopify.MoneyV2;
  id: string;
  lastCharacters: string;
  presentmentAmountUsed: Shopify.MoneyV2;
}

export interface CheckoutResource {
  appliedGiftCards: AppliedGiftCard[];
  completedAt: string | null;
  createdAt: string;
  currencyCode: string;
  discountApplications: Shopify.DiscountApplication[];
  email: null | string;
  id: Shopify.CheckoutId;
  lineItems: Shopify.LineItem[];
  lineItemsSubtotalPrice: Shopify.MoneyV2;
  loaded: boolean;
  note: any;
  orderStatusUrl: string;
  paymentDue: Shopify.MoneyV2;
  ready: boolean;
  requiresShipping: boolean;
  subtotalPrice: Shopify.MoneyV2;
  taxesIncluded: boolean;
  taxExempt: boolean;
  totalPrice: Shopify.MoneyV2;
  totalTax: Shopify.MoneyV2;
  updatedAt: string;
  userErrors?: Array<{ message: string }>;
  webUrl: string;
}

export interface CheckoutDiscountCodeApply {
  checkoutId: Shopify.CheckoutId;
  code: string;
}

export interface ShopifyCartContext {
  addItem: (authState: AuthState, ...lineItems: Shopify.CheckoutLineItemInput[]) => void;
  addCode: ({ checkoutId, code }: CheckoutDiscountCodeApply) => Promise<CheckoutResource>;
  removeItem: (authState: AuthState, lineItemId: Shopify.LineItemId, variant: Shopify.ProductVariant) => Promise<void>;
  removeCode: ({ checkoutId }: { checkoutId: Shopify.CheckoutId }) => Promise<void>;
  resetCart: () => void;
  updateItem: ({
    id,
    quantity,
    variantId,
    customAttributes,
  }: Shopify.CheckoutLineItemUpdateInput) => Promise<void>;
  checkout: CheckoutResource;
  client: {};
  totalItems: number;
  cartVisible: boolean;
  setCartVisible: (cartVisible: boolean) => void;
  shopifyCheckoutId: Shopify.CheckoutId | "" | undefined;
  setShopifyCheckoutId: React.Dispatch<React.SetStateAction<Shopify.CheckoutId | "" | undefined>>;
  onCustomerFetched: (customer: Shopify.Customer) => void;
  localLineItems: LocalLineItem[] | undefined;
  getProductFromVariant: (variantId: string) => Shopify.Product 
}

export enum ShopifyActions {
  SET_LOADING = "set_loading",
  SET_CHECKOUT = "set_checkout",
}
