import styled, { css } from "styled-components";
import { Device, Spacing } from "./constants";
import { AbsoluteCenterCSS } from "./layout";

interface GridProps {
  background?: string;
  center?: boolean;
  columns?: string;
  rows?: string;
  gap?: string;
  $wrap?: boolean;
}

export const Grid = styled.div<GridProps>`
  background: ${(props) => (props.background ? props.background : "transparent")};
  background-size: contain;
  display: grid;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  align-content: ${(props) => (props.center ? "center" : "flex-start")};
  align-items: ${(props) => (props.center ? "center" : "flex-start")};
  grid-template-columns: ${(props) => (props.columns ? props.columns : "auto")};
  grid-template-rows: ${(props) => (props.rows ? props.rows : "auto")};
  gap: ${(props) => (props.gap ? props.gap : "normal")};
  width: 100%;

  @media ${Device.mediumDown} {
    display: flex;
    flex-direction: ${(props) => (props.$wrap ? "column" : "row")};
    overflow-x: ${(props) => (props.$wrap ? "hidden" : "scroll")};
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;
interface ColumnProps {
  background?: string;
  center?: boolean;
  padding?: string;
  margin?: string;
  wrap?: boolean;
}

export const Column = styled.div<ColumnProps>`
  background: ${(props) => (props.background ? props.background : "transparent")};
  display: flex;
  flex-direction: column;
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  align-content: ${(props) => (props.center ? "center" : "flex-start")};
  align-items: ${(props) => (props.center ? "center" : "flex-start")};
  margin: ${(props) => (props.margin ? props.margin : "auto")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  width: 100%;

  &.grid-container {
    margin-bottom: ${Spacing.small};

    @media ${Device.mediumUp} {
      margin-bottom: ${Spacing.large};
    }
  }
`;

interface RowProps {
  background?: string;
  center?: boolean;
  padding?: string;
  margin?: string;
  wrap?: boolean;
}

export const Row = styled.div<RowProps>`
  background: ${(props) => (props.background ? props.background : "transparent")};
  background-size: contain;
  display: flex;
  flex-direction: row;
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  align-content: ${(props) => (props.center ? "center" : "flex-start")};
  align-items: ${(props) => (props.center ? "center" : "flex-start")};
  margin: ${(props) => (props.margin ? props.margin : "auto")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  min-width: 160px;
  width: 100%;

  &.row-workouts-page-1-on-1 {
    .image {
      width: 200px;
      height: auto;

      @media ${Device.mediumUp} {
        width: 380px;
      }
    }
  }

  &.row-workouts-page-music {
    position: relative;
    overflow: visible;

    .video {
      width: 100%;

      @media ${Device.largeUp} {
        max-width: 91.25rem;
      }
    }

    .image-container {
      ${AbsoluteCenterCSS}
      width: 300px;
      height: auto;

      @media ${Device.largeUp} {
        width: min(37.5vw, 540px);
        height: auto;
      }
    }

    .image {
      width: 100%;
    }

    .ui-image {
      position: absolute;
      top: 20%;
      right: -10%;
      width: auto;
      max-height: 50px;

      @media ${Device.largeUp} {
        top: 25%;
        right: -5%;
        max-height: 70px;
      }
    }
  }
`;

export const FoundationGridOverridesCSS = css`
  // Apply foundation grid overrides for the redesign without changing globally

  .grid-container {
    @media screen and (max-width: 39.99875em) {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }

    @media screen and (min-width: 40em) {
      padding-right: 0.625rem;
      padding-left: 0.625rem;
    }

    @media screen and (min-width: 40em) and (max-width: 74.99875em) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
  }

  .grid-padding-x > .cell {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }

  .grid-container:not(.full) > .grid-padding-x {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
`;
