import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { Color, Device, Font } from "./constants";

/**
 * Typography
 */

export const HeadingDisplayCSS = css`
  font-family: ${Font.semibold};
  font-size: 4.5rem;
  line-height: 1;

  @media ${Device.mediumUp} {
    font-size: 8rem;
  }

  @media ${Device.largeUp} {
    font-size: 12.5rem;
    line-height: 0.9;
  }
`;

export const HeadingDisplay = styled.h1`
  ${HeadingDisplayCSS}
`;

// H1

export const Heading1CSS = css`
  font-family: ${Font.semibold};
  font-size: 3.125rem;
  line-height: 1;

  @media ${Device.mediumUp} {
    font-size: 5.625rem;
    line-height: 1;
  }
`;

export const Heading1 = styled.h1`
  ${Heading1CSS}
`;

// H2

export const Heading2CSS = css`
  font-family: ${Font.semibold};
  font-size: 2.5rem;
  line-height: 1.1;

  @media ${Device.mediumUp} {
    font-size: 4.5rem;
    line-height: 1;
  }
`;

export const Heading2 = styled.h2`
  ${Heading2CSS}
`;

// H3

export const Heading3CSS = css`
  font-family: ${Font.semibold};
  font-size: 1.75rem;
  line-height: 1.07;

  @media ${Device.mediumUp} {
    font-size: 2.5rem;
    line-height: 1.2;
  }
`;

export const Heading3 = styled.h3`
  ${Heading3CSS}
`;

// H4

export const Heading4CSS = css`
  font-family: ${Font.medium};
  font-size: 1.25rem;
  line-height: 1.2;

  @media ${Device.mediumUp} {
    font-size: 1.75rem;
    line-height: 1.14;
  }
`;

export const Heading4 = styled.h4`
  ${Heading4CSS}
`;

// Large Body Regular

export const LargeBodyRegularCSS = css`
  font-family: ${Font.regular};
  font-size: 1.25rem;
  line-height: 1.2;

  @media ${Device.mediumUp} {
    font-size: 1.5rem;
    line-height: 1.33;
    letter-spacing: -0.02em;
  }
`;

export const LargeBodyRegular = styled.p`
  ${LargeBodyRegularCSS}
`;

// Large Body Medium

export const LargeBodyMediumCSS = css`
  ${LargeBodyRegularCSS}
  font-family: ${Font.medium};
`;

export const LargeBodyMedium = styled.p`
  ${LargeBodyMediumCSS}
`;

// Large Body Bold

export const LargeBodyBoldCSS = css`
  ${LargeBodyRegularCSS}
  font-family: ${Font.semibold};
`;

export const LargeBodyBold = styled.p`
  ${LargeBodyBoldCSS}
`;

// Body Regular

export const BodyRegularCSS = css`
  font-family: ${Font.regular};
  font-size: 1.125rem;
  line-height: 1.22;

  @media ${Device.mediumUp} {
    font-size: 1.125rem;
    line-height: 1.33;
  }
`;

export const BodyRegular = styled.p`
  ${BodyRegularCSS}
`;

// Body Medium

export const BodyMediumCSS = css`
  ${BodyRegularCSS}
  font-family: ${Font.medium};
`;

export const BodyMedium = styled.p`
  ${BodyMediumCSS}
`;

// Body Bold

export const BodyBoldCSS = css`
  ${BodyRegularCSS}
  font-family: ${Font.semibold};
`;

export const BodyBold = styled.p`
  ${BodyBoldCSS}
`;

// Small Body Regular

export const SmallBodyRegularCSS = css`
  font-family: ${Font.regular};
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: -0.01em;

  @media ${Device.mediumUp} {
    letter-spacing: unset;
  }
`;

export const SmallBodyRegular = styled.p`
  ${SmallBodyRegularCSS}
`;

// Small Body Medium

export const SmallBodyMediumCSS = css`
  ${SmallBodyRegularCSS}
  font-family: ${Font.medium};
`;

export const SmallBodyMedium = styled.p`
  ${SmallBodyMediumCSS}
`;

// Small Body Bold

export const SmallBodyBoldCSS = css`
  ${SmallBodyRegularCSS}
  font-family: ${Font.semibold};
`;

export const SmallBodyBold = styled.p`
  ${SmallBodyBoldCSS}
`;

// XSmall Body Regular

export const XSmallBodyRegularCSS = css`
  font-family: ${Font.regular};
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: -0.01em;

  @media ${Device.mediumUp} {
    font-size: 0.75rem;
    line-height: 1;
    letter-spacing: unset;
  }
`;

export const XSmallBodyRegular = styled.p`
  ${XSmallBodyRegularCSS}
`;

// XSmall Body Medium

export const XSmallBodyMediumCSS = css`
  ${XSmallBodyRegularCSS}
  font-family: ${Font.medium};
`;

export const XSmallBodyMedium = styled.p`
  ${XSmallBodyMediumCSS}
`;

// XSmall Body Bold

export const XSmallBodyBoldCSS = css`
  ${XSmallBodyRegularCSS}
  font-family: ${Font.semibold};
`;

export const XSmallBodyBold = styled.p`
  ${XSmallBodyBoldCSS}
`;

export const PortableTextWrapper = styled.div<{
  $style?: FlattenSimpleInterpolation;
  styleMobile?: FlattenSimpleInterpolation;
  styleDesktop?: FlattenSimpleInterpolation;
}>`
  ${({ $style, styleMobile }) => {
    if (styleMobile) return styleMobile;
    return $style;
  }}

  ${({ styleDesktop }) =>
    styleDesktop &&
    css`
      @media ${Device.largeUp} {
        ${styleDesktop}
      }
    `};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: inherit;
  }

  p {
    white-space: pre-wrap;

    a {
      color: inherit;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const EyebrowCSS = css`
  display: block;
  font-family: ${Font.semibold};
  font-size: 1rem;
  line-height: 1.125;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: ${Color.grayBrand};
  margin-bottom: 1.875rem;
`;

export const Eyebrow = styled.span`
  ${EyebrowCSS}
`;

export const FoundationHeadingOverridesCSS = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: initial;
  }

  h5,
  h6 {
    font-family: ${Font.semibold};
  }
`;
