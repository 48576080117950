import { Shopify } from "@models/shopify.model";
import { getVariantNodes } from "./getVariantNodes";
/**
 * @desc Find the product within an array of products that contains the
 *     provided variant.
 * @param {Shopify.ProductVariant} variant - The variant from which a
 *     product needs to be surfaced.
 * @param {Shopify.Product[]} products - An array of products to search within
 *     for the provided variant.
 * @returns {(Shopify.Product|undefined)} Returns a Shopify.Product if
 *     one is found within the provided products. Otherwise, returns
 *     undefined.
 */
export const findProductFromVariant = (
  variant: Shopify.ProductVariant,
  products: Shopify.Product[]
) => {
  return products.find((product) => {
    const variants = getVariantNodes(product);
    return variants.find((_variant) => _variant.id === variant.id);
  });
};
