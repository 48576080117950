import { Shopify } from "@models/shopify.model";
import { gql } from "@apollo/client";
import { Dispatch } from "react";

export enum ProductAction {
  SET_PRODUCT = "set_product",
  FAILED_FETCH = "fetch_product_fail",
}

export interface ShopifyProductDispatch {
  type: ProductAction;
  product?: Shopify.Product;
  message?: Error;
}

export interface ShopifyProductProviderState {
  products: {
    [x: string]: Shopify.Product;
  };
}

export interface ProductProviderContext {
  dispatch: Dispatch<ShopifyProductDispatch>;
  getShopifyProduct: (
    dispatch: Dispatch<ShopifyProductDispatch>,
    handle: string
  ) => Promise<Shopify.Product>;
  state: ShopifyProductProviderState;
}

export const PRODUCT_QUERY = gql`
  query Product($handle: String) {
    product(handle: $handle) {
      id
      title
      vendor
      images(first: 10) {
        edges {
          node {
            id
            originalSrc
          }
        }
      }
      handle
      description
      descriptionHtml
      tags
      linkedProduct: metafield(namespace: "custom", key: "linked_product") {
        type
        value
        reference {
          ... on Product {
            id
            variants(first: 100) {
              edges {
                node {
                  id
                  availableForSale
                }
              }
            }
          }
        }
      }
      cartDescription: metafield(namespace: "custom", key: "cart_description") {
        value
      }
      bundleOptions1: metafield(namespace: "custom", key: "bundle_options_1") {
        type
        value
      }
      bundleOptions2: metafield(namespace: "custom", key: "bundle_options_2") {
        type
        value
      }
      variants(first: 100) {
        edges {
          node {
            id
            title
            selectedOptions {
              name
              value
            }
            sku
            quantityAvailable
            image {
              originalSrc
            }
            price {
              amount
            }
            compareAtPrice {
              amount
              currencyCode
            }
            availableForSale
            bundle_option_1: metafield(namespace: "custom", key: "variant_option_1") {
              value
            }
            bundle_option_2: metafield(namespace: "custom", key: "variant_option_2") {
              value
            }
          }
        }
      }
      priceRange {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      compareAtPriceRange {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      availableForSale
    }
  }
`;
