import { detect } from "detect-browser";
import get from "lodash-es/get";
import omit from "lodash-es/omit";
import trim from "lodash-es/trim";
import * as queryString from "query-string";

import {
  AuthStateUserProfile,
  AuthStateValue,
  AuthStateCheckoutResource,
  UserAddress,
} from "@providers/profile/ProfileProvider.model";
import { Shopify } from "@models/shopify.model";
import { CheckoutResource } from "@providers/cart";

const NOT_IMPLEMENTED: null = null;
const internalUserRegex = /(getthe)?mirror.com?$/;

/*
Get segment analytics module from window dom. Necessary for methods like analytics.user() which
require analytics to be 'ready'
*/

export enum Event {
  AFFIRM_STARTED = "Affirm Checkout Started",
  ACCOUNT_LOGIN = "Account Login",
  ACCOUNT_LOGOUT = "Account Logout",
  ACCOUNT_UPDATED = "Account Updated",
  CART_VIEWED = "Cart Viewed",
  CHECKOUT_STARTED = "Checkout Started",
  CHECKOUT_STEP_VIEWED = "Checkout Step Viewed",
  CHECKOUT_STEP_COMPLETED = "Checkout Step Completed",
  COUPON_APPLIED = "Coupon Applied",
  COUPON_DENIED = "Coupon Denied",
  COUPON_ENTERED = "Coupon Entered",
  COUPON_REMOVED = "Coupon Removed",
  CREDIT_CARD_FAILED = "Credit Card Purchase Fail",
  CREDIT_CARD_SUCCEEDED = "Credit Card Purchase Success",
  DIGITAL_FREE_TRIAL_STARTED = "Digital Free Trial Started",
  EMAIL_SUBMITTED = "Email Submitted",
  EMAIL_UPDATED = "Email Updated",
  EXIT_LINK_CLICKED = "Exit Link Clicked",
  ORDER_COMPLETED = "Order Completed",
  ORDER_SHARED = "Order Share Link Clicked",
  ORDER_UPDATED = "Order Updated",
  MODAL_OPENED = "Modal Opened",
  MODAL_CLOSED = "Modal Closed",
  PASSWORD_RESET_REQUESTED = "Password Reset Requested",
  PASSWORD_RESET = "Password Reset",
  PAYMENT_INFO_ENTERED = "Payment Info Entered",
  PAYMENT_INFO_UPDATED = "Payment Info Updated",
  PRODUCT_ADDED = "Product Added",
  PRODUCT_CLICKED = "Product Clicked",
  PRODUCT_REMOVED = "Product Removed",
  PRODUCT_REVIEWED = "Product Reviewed",
  PRODUCT_VIEWED = "Product Viewed",
  REDEMPTION_STEP_COMPLETED = "Gift Redemption Step Completed",
  SUBSCRIPTION_TERMS_AGREED = "Subscription Terms Agreed",
  TRIAL_EMAIL_ENTERED = "Trial Email Entered",
  VIDEO_VIEW = "Video View",
}

// ======================
// from src/utils file in legeacy ecomm
export const isBlank = (obj: string | any[]) => {
  if (!obj) return true;
  return typeof obj === "object" ? obj.length === 0 : trim(obj) === "";
};
// ======================

export async function page(auth: AuthStateValue) {
  const title = pageName();
  try {
    console.log(`[Segment] Page '${title}'`);
    window.analytics.page(title, defaultData(auth));
  } catch (e) {
    console.error(e);
  }
}

interface UserLike {
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  isGifter?: boolean;
  isGiftee?: boolean;
}

// Recommended events:
// After a user registers
// After a user logs in
// When a user updates their info (eg changes or adds a new address)
// Upon loading any pages that are accessible by a logged in user (optional)
export async function identify(auth: AuthStateValue, data: UserLike = {}) {
  const { user } = auth;
  const { email } = data;
  try {
    console.log("[Segment] Identify");
    window.analytics.identify(
      auth.session.id,
      {
        ...defaultData(auth),
        ...customerData(user, null, data),
      },
      { ...integrations({ email }) }
    );
  } catch (e) {
    console.error(e);
  }
}

export async function track(event: Event, data: any) {
  try {
    console.log("[Segment] Track", event);
    window.analytics.track(event, data, integrations(data));
  } catch (e) {
    console.error(e);
  }
}

function integrations(data?: any) {
  return {
    integrations: {
      All: true,
      Iterable: !!(data && data.email),
    },
  };
}

function getWindowAttributes() {
  const parts = window.location.hostname.split(".");
  const subdomain =
    process.env.SEGMENT_ENV || (parts.length <= 2 || parts[0] === "www" ? false : parts[0]);
  const DEV = process.env.NODE_ENV !== "production";
  const platform = "ecomm";
  const environment = subdomain || (DEV ? "local" : "production");

  const windowAttributes = {
    platform: platform,
    environment: environment,
  };
  return windowAttributes;
}

export const events = {
  account: {
    login: async (auth: AuthStateValue) => {
      const data = {
        ...defaultData(auth),
        ...customerData(auth.user),
      };
      return track(Event.ACCOUNT_LOGIN, data);
    },
    logout: async (auth: AuthStateValue) => {
      const data = {
        ...defaultData(auth),
        ...customerData(auth.user),
      };
      return track(Event.ACCOUNT_LOGOUT, data);
    },
    updated: async (auth: AuthStateValue) => {
      const data = {
        ...defaultData(auth),
        ...customerData(auth.user),
        shippingAddress: formatAddress(auth.user.address), // TODO: the FE only allows handling billing address, need to add shipping
        billingAddress: formatAddress(auth.user.address),
      };
      return track(Event.ACCOUNT_UPDATED, data);
    },
  },

  cart: {
    view: async (auth: AuthStateValue, checkout: CheckoutResource, products: Shopify.Product[]) => {
      const data = {
        ...defaultData(auth),
        cartId: checkout && checkout.id,
        products: checkout && productsData(checkout, products),
        ...orderReferralData(auth.checkout),
      };
      return track(Event.CART_VIEWED, data);
    },
    productAdded: async (
      auth: AuthStateValue,
      checkout: CheckoutResource,
      product: Shopify.Product,
      item: Shopify.CheckoutLineItemInput
    ) => {
      const data = {
        ...defaultData(auth),
        ...couponsData(checkout),
        ...lineItemData(checkout, product, item),
        ...orderReferralData(auth.checkout),
      };
      return track(Event.PRODUCT_ADDED, data);
    },
    productRemoved: async (
      auth: AuthStateValue,
      lineItem: Shopify.LineItem,
      checkout: CheckoutResource,
      product: Shopify.Product
    ) => {
      const data = {
        ...defaultData(auth),
        ...couponsData(checkout),
        ...lineItemData(checkout, product, undefined, lineItem),
        ...orderReferralData(auth.checkout),
      };
      return track(Event.PRODUCT_REMOVED, data);
    },
  },

  checkout: {
    started: async (
      auth: AuthStateValue,
      checkout: CheckoutResource,
      products: Shopify.Product[]
    ) => {
      const data = {
        ...defaultData(auth),
        ...customerData(auth.user, checkout),
        ...couponsData(checkout),
        products: productsData(checkout, products),
        coupon: NOT_IMPLEMENTED,
        currency: checkout.currencyCode,
        discount: NOT_IMPLEMENTED,
        orderId: checkout.id,
        revenue: NOT_IMPLEMENTED,
        shipping: NOT_IMPLEMENTED,
        tax: 0,
        ...orderReferralData(auth.checkout),
      };
      return track(Event.CHECKOUT_STARTED, data);
    },
  },

  email: {
    submitted: async (auth: AuthStateValue, email: string) => {
      const data = {
        ...defaultData(auth),
        email,
      };
      return track(Event.EMAIL_SUBMITTED, data);
    },
    // This occurs on account management pages
    // UNIMPLEMENTED
    updated: async (auth: AuthStateValue, email: string) => {
      const data = {
        ...defaultData(auth),
        email,
      };
      return track(Event.EMAIL_UPDATED, data);
    },
  },

  modal: {
    opened: async (auth: AuthStateValue, modalName: string) => {
      const data = {
        ...defaultData(auth),
        modalName,
      };
      return track(Event.MODAL_OPENED, data);
    },
    closed: async (auth: AuthStateValue, modalName: string) => {
      const data = {
        ...defaultData(auth),
        modalName,
      };
      return track(Event.MODAL_CLOSED, data);
    },
  },

  password: {
    // Fires when user requests a password reset
    reset: async (auth: AuthStateValue, email: string) => {
      const data = {
        ...defaultData(auth),
        email,
      };
      return track(Event.PASSWORD_RESET_REQUESTED, data);
    },
    // Fires when user enters a new password
    change: async (auth: AuthStateValue, email: string) => {
      const data = {
        ...defaultData(auth),
        email,
      };
      return track(Event.PASSWORD_RESET, data);
    },
  },

  product: {
    reviewed: async (auth: AuthStateValue, review) => {
      const data = {
        ...defaultData(auth),
        productId: review.product_id,
        rating: review.rating,
        reviewBody: review.review,
      };
      return track(Event.PRODUCT_REVIEWED, data);
    },
    viewed: async (
      auth: AuthStateValue,
      checkout: CheckoutResource,
      product: Shopify.ProductVariant,
      isAccessory: boolean = false
    ) => {
      const data = {
        ...defaultData(auth),
        ...couponsData(checkout),
        brand: product.title || "",
        category: "",
        coupon: NOT_IMPLEMENTED, // NA
        currency: NOT_IMPLEMENTED, // NA
        imageUrl: NOT_IMPLEMENTED,
        emailImageUrl: product.image.src || "",
        name: product.title,
        position: NOT_IMPLEMENTED, // NA
        price: !isAccessory ? parseFloat(product.price.amount) : 0,
        productId: product.id,
        quantity: NOT_IMPLEMENTED, // NA
        sku: !isAccessory ? product.sku : "",
        variant: NOT_IMPLEMENTED, // Don't 'view' variants
        ...orderReferralData(auth.checkout),
      };
      return track(Event.PRODUCT_VIEWED, data);
    },
  },

  exitLink: {
    // this is only on the 'showroom' page
    click: async (auth: AuthStateValue, url: string, storeName: string) => {
      const data = {
        ...defaultData(auth),
        externalUrl: url,
        store: storeName,
      };
      return track(Event.EXIT_LINK_CLICKED, data);
    },
  },
  paidDigital: {
    trialSignUp: async (auth: AuthStateValue, email: string) => {
      const data = {
        ...defaultData(auth),
        email,
      };
      return track(Event.TRIAL_EMAIL_ENTERED, data);
    },
  },
};

function defaultData(auth: AuthStateValue) {
  const ip = (document.querySelector("#__NEXT_DATA__") as any).dataset.ip;
  const qs = queryString.parse(location.search);
  const browser = detect(); // If browser is not detected this will be null
  const { session, user } = auth;
  const guestToken = session.id;
  const windowAttributes = getWindowAttributes();
  const data = {
    ...(user ? customerData(auth.user) : {}),
    guestToken,
    initialReferrer: document.referrer,
    initialReferringDomain: domain(document.referrer),
    browser: get(browser, "name", "Unknown"),
    browserVersion: get(browser, "version", "Unknown"),
    countryCode: process.env.COUNTRY_CODE,
    environment: windowAttributes.environment,
    internalUser: user ? internalUserRegex.test(user.email) : false,
    ipAddress: ip,
    isUser: !!(user && user.uuid),
    pageName: pageName(),
    os: navigator.platform,
    platform: windowAttributes.platform,
    userId: (user && user.uuid) || "",
    url: location.href,
    utmCampaign: qs.utm_campaign || session.utm_campaign,
    utmContent: qs.utm_content || session.utm_content,
    utmMedium: qs.utm_medium || session.utm_medium,
    utmSource: qs.utm_source || session.utm_source,
    utmTerm: qs.utm_term || session.utm_term,
    external_id: auth.external_id,
  };
  return data;
}

function referralData(user: AuthStateUserProfile) {
  return {
    referralCode: user?.referral_code ? user.referral_code : "",
  };
}

function orderReferralData(checkout: AuthStateCheckoutResource) {
  if (!checkout.is_referral) return {};

  return {
    orderReferralCode: checkout.referral_code,
    orderReferrerEmail: checkout.referral_email,
  };
}

function lineItemData(
  checkout: CheckoutResource,
  product: Shopify.Product,
  item?: Shopify.CheckoutLineItemInput,
  lineItem?: Shopify.LineItem
) {
  let variant;
  let url;

  if (lineItem) {
    variant = lineItem.variant;
    url = "";
  } else if (item) {
    const variantNode = product.variants.edges.find((variant) => variant.node.id == item.variantId);
    variant = variantNode?.node;
    const productTypeObj = item.customAttributes
      ? item.customAttributes?.find((obj) => obj.key === "_productType")
      : undefined;
    const productType = productTypeObj ? productTypeObj["value"] : "mirror";
    url = productUrl(productType);
  }

  return [
    {
      brand: product.vendor || "",
      category: variant.title || "",
      cartId: checkout.id || "",
      imageUrl: variant.image.src || "",
      emailImageUrl: variant.image.src || "",
      url: url,
      name: product.title || "",
      position: NOT_IMPLEMENTED,
      price: parseFloat(variant.price.amount),
      productId: variant.id.toString(),
      quantity: item ? item.quantity : 1,
      sku: variant.sku || "",
      variant: variant.title,
    },
  ];
}

function getAnalyticsProductFromVariant(products, variantId) {
  let selectedProduct;

  products.forEach((product, _index) => {
    const variantEdges = product?.variants?.edges;
    const foundVariant = variantEdges.find((variant) => variant.node.id === variantId);
    if (foundVariant) {
      selectedProduct = product;
    }
  });

  return selectedProduct;
}

function productsData(checkout: CheckoutResource, products: Shopify.Product[]) {
  return checkout.lineItems.map((item) => {
    const { variant } = item;
    const product = getAnalyticsProductFromVariant(products, variant.id);
    const id = variant.id.toString();

    return {
      brand: product.vendor || item.variantTitle || "",
      category: variant.title || "", // not used
      variant: variant.title || "",
      name: item.title || "",
      sku: variant.sku || "",
      emailImageUrl: "", // not used
      url: "", // not used
      imageUrl: variant.image.src || "",
      quantity: item.quantity,
      price: parseFloat(variant.price.amount), // iterable wants a number
      productId: id,
      id: id,
    };
  });
}

function formatAddress(address?: UserAddress) {
  if (!address) return {};
  return {
    ...omit(address, [
      "address1",
      "address2",
      "alternative_phone",
      "full_name",
      "company",
      "id",
      "state_id",
      "state_text",
      "state_name",
      "country",
      "country_id",
      "zipcode",
    ]),
    zip: address.postal_code,
    state: address.state,
    streetOne: address.line1,
    streetTwo: address.line2,
  };
}

function link(path: string) {
  return `${location.protocol}//${location.host}${path[0] === "/" ? "" : "/"}${path}`;
}

function domain(url: string) {
  if (url === "") return url;
  const a = document.createElement("a");
  a.href = url;
  return a.hostname;
}

// SQUARE PEG ROUND HOLE.
// They want "product URLs" that we don't have
function productUrl(productType: string) {
  return [
    document.location.protocol,
    "//",
    document.location.hostname,
    "/",
    productType === "accessories" ? "accessories" : `shop/${productType}`,
  ].join("");
}

function pageName() {
  return trim(document.title.replace(/\| MIRROR\s*$/, ""));
}

function userNames(user) {
  let first_name;
  let last_name;
  try {
    const names = user.name.split(" ");
    first_name = names[0];
    last_name = names.length > 1 ? names[-1] : "";
  } catch {
    first_name = "";
    last_name = "";
  }
  return [first_name, last_name];
}

function customerData(user: AuthStateUserProfile, order?: CheckoutResource, data?: UserLike) {
  const v = "value";
  const [giftFirstName, ...giftLastName] = get(order, "gift.from_name", "").split(" ");
  const [first_name, last_name] = userNames(user);

  const firstName = [
    giftFirstName,
    get(data, "firstName"),
    get(document.querySelector("[name='firstname']"), v),
    get(order, "ship_address.firstname"),
    get(user, "first_name"),
    first_name,
  ].find((s) => !isBlank(s));
  const lastName = [
    giftLastName.join(" "),
    get(data, "lastName"),
    get(document.querySelector("[name='lastname']"), v),
    get(order, "ship_address.lastname"),
    get(user, "last_name"),
    last_name,
  ].find((s) => !isBlank(s));
  const phoneNumber = [
    get(data, "phone"),
    get(document.querySelector("[name='phone']"), v),
    get(order, "ship_address.phone"),
    get(user, "phone"),
    get(user, "profile.phone_number"),
  ].find((s) => !isBlank(s));
  const email = [
    get(data, "email"),
    get(document.querySelector("[name='email']"), v),
    get(user, "email"),
    get(order, "email"),
  ].find((s) => !isBlank(s));
  return {
    ...orderLinks(order),
    ...userSubscriptionData(user),
    firstName,
    lastName,
    phoneNumber,
    email,
    isGifter: !!get(order, "gift") || get(user, "is_gifter", get(data, "isGifter")),
    isGiftee: get(user, "is_giftee", get(data, "isGiftee")),
    ...referralData(user),
  };
}

function orderLinks(checkout?: CheckoutResource) {
  return checkout
    ? {
        spreeUrl: "", // spreeUrl isn't used by Data/Marketing teams, we can just send an empty string
        // This is referred to as "membership" everywhere else but this event value
        subscriptionUrl: `${window.location.host}/billing`,
      }
    : {};
}

function couponsData(checkout: {} | null) {
  const coupons: Shopify.DiscountApplication[] = get(checkout, "discountApplications", []);
  const formattedCoupons = coupons.reduce((acc, c) => {
    const value = Math.abs(get(c.value, "amount", 0));
    // A given promo can cause multiple adjustments, we want to
    // consolidate the value amount per code entered
    const existing = acc.find((val) => val.code === c.code);
    if (existing) {
      existing.value += value;
    } else {
      acc.push({
        code: c.code,
        description: c.description,
        value,
      });
    }
    return acc;
  }, []);

  return {
    // Added for backwards-compatibility
    coupon: get(formattedCoupons, "[0].code"),
    coupons: formattedCoupons,
  };
}

function userSubscriptionData(user) {
  // this is a little different in that `subscription_active` could be true but there could be a subscription_override, therefore there would not be a subscription for auto_renew to be relevant
  const sub = user?.subscription_active;
  const data = {
    subscriptionStatus: sub ? "active" : "inactive",
  };
  return sub
    ? {
        ...data,
        autoRenewalStatus: user?.auto_renew ? "on" : "off",
      }
    : data;
}
