import type { StringifiableRecord } from "query-string";
import type { AttributeInput } from "shopify-buy";
import type { MarketingTokens } from "@providers/marketing-tokens";

import { Checkout, CheckoutLineItem } from "shopify-buy";

/**
 *  Given a checkout item, iterate over the associated line items and see if any are missing variants.
 * @param {ShopifyBuy.Checkout} checkout A checkout object from the Shopify Buy SDK.
 * @returns {boolean} True if any line items have missing variants, otherwise false.
 */
export const isCheckoutMissingVariants = (checkout: Checkout) => {
  checkout.lineItems.some((lineItem: CheckoutLineItem) => {
    return !lineItem.variant;
  });
};

/**
 * Create an object with all applicable tracking values for use in creating checkout queries.
 * @param {MarketingTokens} marketingTokens The marketing tokens captured via cookies and query parameters.
 * @param {string | undefined} anonymousId The
 * @returns {StringifiableRecord} An object with tracking values set to correct keys for checkout creation.
 */
export const createCheckoutQueryObject = (
  marketingTokens: MarketingTokens,
  anonymousId: string | undefined
): StringifiableRecord => {
  let checkoutQuery: StringifiableRecord = marketingTokens;
  if (anonymousId) {
    checkoutQuery = {
      ...checkoutQuery,
      anonymous_id: anonymousId,
    };
  }
  return checkoutQuery;
};

export const createCheckoutCartOptions = (
  marketingTokens: MarketingTokens,
  anonymousId: string | undefined
): {
  customAttributes: AttributeInput[];
} => {
  const customAttributes = [];
  const checkoutQuery = createCheckoutQueryObject(marketingTokens, anonymousId);
  for (const key in checkoutQuery) {
    customAttributes.push({
      key,
      value: checkoutQuery[key],
    });
  }
  return {
    customAttributes,
  };
};
