import { Shopify } from "./shopify.model";

export enum LocalStorageKeys {
  SHOPIFY_TOKEN = "shopify-token",
  AUTH_TOKEN = "auth-token",
  AUTH_STATE = "auth-state",
  SHOPIFY_CHECKOUT = "shopifyCheckout",
  LINE_ITEMS = "lineItems",
}

export interface LocalLineItem extends Shopify.LineItem {
  addedAt: number;
}
