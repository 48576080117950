import React, { createContext, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useLocalStorage } from "react-use";
import { Shopify } from "@models/shopify.model";
import {
  CheckoutDiscountCodeApply,
  CheckoutResource,
  ShopifyActions,
  ShopifyCartContext,
} from "./CartProvider.model";
import { useShopifyProduct } from "@providers/product-provider";
import { useMarketingTokens, ValidTokens } from "@providers/marketing-tokens";
import { findProductFromVariant } from "@utils/findProductFromVariant";
import { getVariantNodes } from "@utils/getVariantNodes";
import { LocalLineItem, LocalStorageKeys } from "@models/storage.model";
import { associateCheckoutToUser } from "@utils/checkoutAssociation";
import { AuthState } from "@providers/profile";
import { events } from "@providers/analytics/analytics";
import { createCheckoutCartOptions, isCheckoutMissingVariants } from "@utils/shopify/checkout";
import { useAnonymousIdWithConsent } from "@utils/segment/hooks/useAnonymousIdWithConsent";

const ShopifyContext = createContext<ShopifyCartContext | null>(null);
const { Provider } = ShopifyContext;

const shopifyCheckoutReducer = (state, action) => {
  switch (action.type) {
    case ShopifyActions.SET_LOADING:
      return { ...state, loaded: false };
    case ShopifyActions.SET_CHECKOUT:
      const { lineItems = [], subtotalPrice = 0, webUrl = "" } = action.payload;
      return { ...action.payload, lineItems, subtotalPrice, webUrl, loaded: true };
    default:
      throw new Error(`Action of type ${action.type} does not exist.`);
  }
};

const useShopifyCart = () => {
  const context = useContext(ShopifyContext);
  if (context == undefined) {
    throw new Error("ShopifyContext must be used within a ShopifyCartProvider");
  }
  return context;
};

const ShopifyCartProvider = ({ client, children }) => {
  const {
    state: { products },
  } = useShopifyProduct();
  const marketingTokens = useMarketingTokens();
  const anonymousId = useAnonymousIdWithConsent();
  const [shopifyCheckoutId, setShopifyCheckoutId] = useLocalStorage<Shopify.CheckoutId | "">(
    LocalStorageKeys.SHOPIFY_CHECKOUT,
    ""
  );
  const [localLineItems, setLocalLineItems] = useLocalStorage<LocalLineItem[]>(
    LocalStorageKeys.LINE_ITEMS,
    []
  );
  const [cartVisible, setCartVisible] = useState(false);
  const [checkout, dispatch] = useReducer(shopifyCheckoutReducer, {
    loaded: false,
    subtotalPrice: 0,
    lineItems: [],
    webUrl: "",
  });

  const totalItems = checkout.lineItems.reduce(
    (cumulative: number, item: { quantity: number }) => cumulative + item.quantity,
    0
  );

  const getProductFromVariant = (variantId: string) => {
    const stateProducts = products;
    const keys = Object.keys(stateProducts);
    let selectedProduct;

    keys.forEach((key, index) => {
      const product = stateProducts[key];
      const variantEdges = product?.variants?.edges;
      const foundVariant = variantEdges.find((variant) => variant.node.id === variantId);
      if (foundVariant) {
        selectedProduct = product;
      }
    });

    return selectedProduct;
  };

  // add multiple CheckoutLine items
  const addItem = async (authState: AuthState, ...lineItems: Shopify.CheckoutLineItemInput[]) => {
    dispatch({ type: ShopifyActions.SET_LOADING });

    const temporalCheckout: CheckoutResource = await client.checkout.addLineItems(
      shopifyCheckoutId,
      lineItems
    );

    // dispatch cart.productAdded event for each lineItem added (except membership products)
    lineItems.forEach((item) => {
      const product = getProductFromVariant(item.variantId);
      if (product && !product.tags.includes("membership")) {
        events.cart.productAdded(authState.value, checkout, product, item);
      }
    });

    const addedLineItems = lineItems.reduce(
      (_addedLineItems: LocalLineItem[], lineItemInput: Shopify.CheckoutLineItemInput, index) => {
        const lineItem = temporalCheckout.lineItems.find(
          (lineItem) => lineItem.variant.id === lineItemInput.variantId
        );
        if (!lineItem) {
          console.error("Matching line item could not be found.");
          return _addedLineItems;
        }

        _addedLineItems.push({
          ...lineItem,
          /**
           * Account for cases where multiple items are added at once
           * by slightly increasing the time based on index.
           */
          addedAt: Date.now() + index,
        });
        return _addedLineItems;
      },
      []
    );

    setLocalLineItems([...(localLineItems || []), ...addedLineItems]);

    dispatch({
      type: ShopifyActions.SET_CHECKOUT,
      payload: temporalCheckout,
    });
  };

  const removeItem = async (
    authState: AuthState,
    lineItemId: Shopify.LineItemId,
    variant: Shopify.ProductVariant
  ) => {
    dispatch({ type: ShopifyActions.SET_LOADING });
    const linesToRemove = [lineItemId];
    /**
     * get the Products associated to the current line items.
     */
    const productsInCheckout: Shopify.Product[] = checkout.lineItems.map(
      (line: Shopify.LineItem) => products[line.variant.product.handle]
    );
    /**
     * Get the product associated with the line item that will be removed.
     */
    const productToRemove = findProductFromVariant(variant, productsInCheckout);

    /**
     * Get the line item that will be removed.
     */
    const lineItemToRemove = checkout.lineItems.find(
      (item: Shopify.LineItem) => item.id === lineItemId
    );

    /**
     * dispatch productRemoved event
     */
    events.cart
      .productRemoved(authState.value, lineItemToRemove, checkout, productToRemove)
      .catch((e) => {
        console.error(e);
      });

    /**
     * If the productToRemove has no linkedProduct, remove the corresponding
     * line item and dispatch.
     */
    if (!productToRemove?.linkedProduct) {
      const temporalCheckout = await client.checkout.removeLineItems(
        shopifyCheckoutId,
        linesToRemove
      );

      setLocalLineItems(
        localLineItems ? localLineItems.filter((line) => !linesToRemove.includes(line.id)) : []
      );
      dispatch({
        type: ShopifyActions.SET_CHECKOUT,
        payload: temporalCheckout,
      });
      return;
    }
    /**
     * The product to be removed has a linked product.
     *
     * Check if any other line items are associated to the same linked
     * product. If one is found, leave the linked product alone.
     *
     * However, if no other line items are associated with the linked
     * product, then the linked product should be removed alongside
     * the product being removed.
     */
    const [{ id: linkedVariantId }] = getVariantNodes(productToRemove.linkedProduct.reference);
    const linkedLineItem: Shopify.LineItem | undefined = checkout.lineItems.find(
      (line: Shopify.LineItem) => line.variant.id === linkedVariantId
    );
    /**
     * This scenario shouldn't occur, so raise an error if it does.
     */
    if (!linkedLineItem) {
      console.error("No linkedLineItem was found. Only the LineItem will be removed.");
      const temporalCheckout = await client.checkout.removeLineItems(
        shopifyCheckoutId,
        linesToRemove
      );

      setLocalLineItems(
        localLineItems ? localLineItems.filter((line) => !linesToRemove.includes(line.id)) : []
      );
      dispatch({
        type: ShopifyActions.SET_CHECKOUT,
        payload: temporalCheckout,
      });
      return;
    }
    /**
     * Find the lineItems that will remain after the lineItem being removed is gone.
     * Exclude the linkedLineItem, since we are determining whether or not it should
     * remain.
     */
    const lineItemsToRemain: Shopify.LineItem[] = checkout.lineItems.filter(
      (line: Shopify.LineItem) => line.id !== lineItemId && line.id !== linkedLineItem.id
    );
    /**
     * if an associatedLineItem exists, it means one (or more) *remaining* lineItems
     * is linked to the linkedLineItem. the linkedLineItem should not be removed.
     */
    const associatedLineItem = lineItemsToRemain.find((line) => {
      const product = products[line.variant.product.handle];
      const [referencedVariant] = getVariantNodes(product.linkedProduct?.reference);
      return referencedVariant?.id === linkedVariantId;
    });
    /**
     * When no associatedLineItem is found, the linkedLineItem can be removed in tandem
     * with the lineItem being removed.
     */
    if (!associatedLineItem) linesToRemove.push(linkedLineItem.id);

    const temporalCheckout = await client.checkout.removeLineItems(
      shopifyCheckoutId,
      linesToRemove
    );

    setLocalLineItems(
      localLineItems ? localLineItems.filter((line) => !linesToRemove.includes(line.id)) : []
    );
    dispatch({
      type: ShopifyActions.SET_CHECKOUT,
      payload: temporalCheckout,
    });
  };

  const resetCart = () => {
    setShopifyCheckoutId("");

    setLocalLineItems([]);
    dispatch({
      type: ShopifyActions.SET_LOADING,
    });
  };

  const updateItem = async ({
    id,
    quantity,
    variantId,
    customAttributes,
  }: Shopify.CheckoutLineItemUpdateInput) => {
    dispatch({ type: ShopifyActions.SET_LOADING });

    const temporalCheckout: CheckoutResource = await client.checkout.updateLineItems(
      shopifyCheckoutId,
      [
        {
          id,
          quantity,
          variantId,
          customAttributes,
        },
      ]
    );
    /**
     * When a line item's quantity is updated, it's ID stays intact.
     *
     * When a line item's variant is updated (e.g - selecting a new weight value
     * for the studio weights within CartItem), the line item's ID is changed in
     * the updated checkout. Use a combination of these values to identify
     * the updated line item to cover both cases.
     *
     */
    const updatedLineItem = temporalCheckout.lineItems.find(
      (line) => line.id === id || line.variant.id === variantId
    );

    if (updatedLineItem) {
      /**
       * The local line item retains the _old_ ID until we replace it.
       */
      const matchingLocalLine = localLineItems?.find((line) => line.id === id);
      const filteredLocalLines = localLineItems?.filter((line) => line.id !== id) || [];

      setLocalLineItems([
        ...filteredLocalLines,
        {
          ...updatedLineItem,
          addedAt: matchingLocalLine?.addedAt || Date.now(),
        },
      ]);
    }

    dispatch({
      type: ShopifyActions.SET_CHECKOUT,
      payload: temporalCheckout,
    });
  };

  const addCode = async ({
    checkoutId,
    code,
  }: CheckoutDiscountCodeApply): Promise<CheckoutResource> => {
    dispatch({ type: ShopifyActions.SET_LOADING });
    const temporalCheckout = await client.checkout.addDiscount(checkoutId, code);

    dispatch({
      type: ShopifyActions.SET_CHECKOUT,
      payload: temporalCheckout,
    });

    return temporalCheckout;
  };

  const removeCode = async ({ checkoutId }: { checkoutId: string }) => {
    dispatch({ type: ShopifyActions.SET_LOADING });
    const temporalCheckout = await client.checkout.removeDiscount(checkoutId);

    dispatch({
      type: ShopifyActions.SET_CHECKOUT,
      payload: temporalCheckout,
    });
  };

  const onCustomerFetched = async ({ lastIncompleteCheckout }: Shopify.Customer) => {
    if (!shopifyCheckoutId) throw new Error("No existing Checkout ID.");
    if (lastIncompleteCheckout?.id === shopifyCheckoutId) return;
    /**
     * Associate the existing checkout when:
     * 1 - Customer's last associated checkout was completed or Customer
     *     has never had a checkout associated to their account.
     * 2 - An active checkout with line items exists.
     * 3 - The last incomplete checkout doesn't have any line items.
     */
    const associateExistingCheckout =
      !lastIncompleteCheckout?.id ||
      !!checkout.lineItems.length ||
      !lastIncompleteCheckout.lineItems.edges.length;
    /**
     * Additionally, only associate the existing checkout when it isn't
     * already associated, which is indicated by an email address.
     */
    if (associateExistingCheckout && !checkout.email) {
      associateCheckoutToUser({ id: shopifyCheckoutId });
    }
    /**
     * If the last incomplete checkout has line items and the current
     * checkout doesn't, then the last incomplete checkout should be
     * set as the active checkout.
     */
    if (!!lastIncompleteCheckout?.lineItems.edges.length && !checkout.lineItems.length) {
      setShopifyCheckoutId(lastIncompleteCheckout.id);
    }
  };

  const cartOptions = useMemo(() => {
    return createCheckoutCartOptions(marketingTokens, anonymousId);
  }, [marketingTokens, anonymousId]);

  useEffect(() => {
    const createOrGetExistingCheckout = async () => {
      let temporalCheckout: CheckoutResource;

      if (shopifyCheckoutId) {
        temporalCheckout = await client.checkout.fetch(shopifyCheckoutId);
      }

      if (temporalCheckout && !temporalCheckout?.completedAt) {
        temporalCheckout = await client.checkout.updateAttributes(shopifyCheckoutId, cartOptions);
      } else if (
        !temporalCheckout ||
        temporalCheckout?.completedAt ||
        isCheckoutMissingVariants(temporalCheckout as any)
      ) {
        temporalCheckout = await client.checkout.create(cartOptions);
      }

      setShopifyCheckoutId(temporalCheckout.id);
      return temporalCheckout;
    };

    const dispatchCheckout = async () => {
      const checkout = await createOrGetExistingCheckout();
      const localLineItems = checkout.lineItems.map(
        (checkoutLineItem: Shopify.LineItem): LocalLineItem => {
          return {
            ...checkoutLineItem,
            addedAt: Date.now(),
          };
        }
      );
      setLocalLineItems(localLineItems);
      dispatch({
        type: ShopifyActions.SET_CHECKOUT,
        payload: checkout,
      });
    };

    dispatchCheckout();
  }, [setLocalLineItems, shopifyCheckoutId, setShopifyCheckoutId, client, cartOptions]);

  return (
    <Provider
      value={{
        addItem,
        addCode,
        removeItem,
        removeCode,
        resetCart,
        updateItem,
        checkout,
        client,
        totalItems,
        cartVisible,
        setCartVisible,
        shopifyCheckoutId,
        setShopifyCheckoutId,
        onCustomerFetched,
        localLineItems,
        getProductFromVariant,
      }}>
      {children}
    </Provider>
  );
};

export { ShopifyCartProvider, useShopifyCart };

export default ShopifyCartProvider;
