import type { CategoryPreferences, Destination } from "@segment/consent-manager/types/types";

const UNCATEGORIZED_INTEGRATIONS = [];

const NON_ESSENTIAL_INTEGRATIONS = [
  "Facebook Pixel",
  "FullStory",
  "Google Analytics",
  "Google Tag Manager",
  "Iterable",
  "Kustomer",
  "MovableInk",
  "Pinterest Tag",
  "Impact Partnership Cloud",
  "Visual Website Optimizer",
  "Webhooks",
];

/**
 * Reducer function to take all integration strings and set them equal to true
 * @param preferences Accumulator object for initial preferences
 * @param integration String name of the current integration being processed
 * @returns An object with all integrations as keys with their corresponding values set to True
 */
const initialPreferenceReducer = (preferences, integration) => {
  return {
    ...preferences,
    [integration]: true,
  };
};

export const segmentCustomCategories = {
  "Non-Essential": {
    purpose: "A consent category for all non-essential tracking",
    integrations: [...NON_ESSENTIAL_INTEGRATIONS],
  },
};

// Written like this to make it easy to extend. Any new custom categories or uncategorized destinations will automatically be picked up.
export const segmentInitialPreferences = [
  Object.keys(segmentCustomCategories),
  ...UNCATEGORIZED_INTEGRATIONS,
].reduce(initialPreferenceReducer, {});

/**
 * Function expected by ConsentManagerBuilder to map destination preferences to custom categories
 * @param destinations An array of destinations handled by the consent manager
 * @param preferences An object with keys equal to either a custom category name or an uncategorized destination name,
 *                    with corresponding boolean values for consent
 * @returns An object with the filled out `destinationPreferences` and `customPreferences`
 */
export const segmentMapCustomPreferences = (
  destinations: Destination[],
  preferences: CategoryPreferences
) => {
  const destinationPreferences = {};
  const customPreferences = {};

  for (const preferenceName of Object.keys(segmentCustomCategories)) {
    const value = preferences[preferenceName];
    if (typeof value === "boolean" || typeof value === "string") {
      customPreferences[preferenceName] = value;
    } else {
      customPreferences[preferenceName] = true;
    }
  }

  destinations.forEach((destination) => {
    // Set uncategorized destinations, or undefined for category destinations
    destinationPreferences[destination.id] = preferences[destination.id];
    // Find and set value for category destinations
    if (destinationPreferences[destination.id] === undefined) {
      Object.entries(segmentCustomCategories).forEach(([categoryName, { integrations }]) => {
        const isCategoryIntegration = integrations.includes(destination.id);
        if (isCategoryIntegration) {
          destinationPreferences[destination.id] = customPreferences[categoryName];
        }
      });
    }
  });

  return { destinationPreferences, customPreferences };
};
