import AnchorScroll from "@components/anchor-scroll";
import { ShopifyCartProvider } from "@providers/cart/CartProvider";
import MarketingTokensProvider from "@providers/marketing-tokens";
import { ShopifyProductProvider } from "@providers/product-provider";
import { ProfileProvider } from "@providers/profile";
import { GlobalStyle } from "@styles/global";
import SegmentServices from "@utils/segment/fixtures/SegmentServices";
import shopifyClient from "clients/shopify-client";
import type { AppProps } from "next/app";
import Script from "next/script";
import { CookiesProvider } from "react-cookie";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "@styles/scss/index.scss";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <GlobalStyle />
      <SegmentServices>
        <CookiesProvider>
          <MarketingTokensProvider>
            <Elements stripe={stripePromise}>
              <ShopifyProductProvider>
                <ShopifyCartProvider client={shopifyClient}>
                  <ProfileProvider>
                    <AnchorScroll />
                    <Component {...pageProps} />
                  </ProfileProvider>
                </ShopifyCartProvider>
              </ShopifyProductProvider>
            </Elements>
          </MarketingTokensProvider>
        </CookiesProvider>
      </SegmentServices>
    </>
  );
}
