import { ReactNode } from "react";

export enum ValidTokens {
  irclickid = "irclickid",
  utm_campaign = "utm_campaign",
  utm_content = "utm_content",
  utm_medium = "utm_medium",
  utm_source = "utm_source",
  utm_term = "utm_term",
}

export type MarketingCookies = {
  mirror_marketingTokens?: {
    [key in ValidTokens]?: string;
  };
  [string: string]: unknown;
};

export type MarketingTokens = {
  [key in ValidTokens]?: string;
};

export interface MarketingTokensProviderProps {
  children?: ReactNode;
}
