import { ProfileAction, ProfileProviderDispatch } from "@providers/profile/ProfileProvider.model";
import { Dispatch } from "react";
/**
 * @desc Checks a settled promise result or an array of settled promise
 *     results for an expired auth token and logs out the user if one is
 *     found. Throws errors, if found.
 * @param settledPromise - A Settled Promise or an array of Settled Promises.
 * @param dispatch - ProfileProviderDispatch function
 * @returns value of promise or array of promises
 */
export function handleResults(
  settled: PromiseSettledResult<any> | PromiseSettledResult<any>[],
  dispatch: Dispatch<ProfileProviderDispatch>
) {
  let errors;

  if (Array.isArray(settled)) {
    errors = settled.filter((result) => result.status === "rejected");
  } else {
    errors = settled.status === "rejected" ? [settled] : null;
  }
  /**
   * Find an expired token error and log out the user if found.
   */
  const expiredAuthToken = !!errors.find(
    (result) => ["error_expired_token", "error_invalid_token"].includes(result.reason?.response?.body?.errors?.error_code)
  );
  if (expiredAuthToken) dispatch({ type: ProfileAction.USER_LOGOUT });
  /**
   * Throw errors within settled promises if they exist,
   * regardless of their reason.
   */
  if (errors.length) throw errors;
  /**
   * Return resolved and rejected promise results.
   * Flattened to account for case where the settled is
   * an array.
   */
  return [settled].flat().map((result) => {
    if (result.status !== "fulfilled") return null;
    return result.value;
  });
}
