import { useMemo } from "react";
import { useCookies } from "react-cookie";

import { SEGMENT_ANONYMOUS_ID_COOKIE } from "@utils/constants";
import { useConsentManager } from "@providers/consent";
import { isTrackingAllowed } from "../isTrackingAllowed";

/**
 * React hook to get the anonymousId from segment when consent is provided.
 * Will return `undefined` if tracking is not allowed.
 * @returns {string | undefined} The current anonymousId if consent is provided.
 */
export const useAnonymousIdWithConsent = (): string | undefined => {
  // Hooks
  const { preferences } = useConsentManager();
  const [cookies] = useCookies([SEGMENT_ANONYMOUS_ID_COOKIE]);

  // Memoized values
  const anonymousId = useMemo(() => {
    let memoizedValue;
    if (isTrackingAllowed(preferences)) {
      memoizedValue = cookies[SEGMENT_ANONYMOUS_ID_COOKIE];
    }
    return memoizedValue;
  }, [cookies, preferences]);

  return anonymousId;
};
