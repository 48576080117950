import Client from "shopify-buy";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

// Initializing a client to return content in the store's primary language
const client = Client.buildClient({
  apiVersion: '2022-10',
  domain: process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN,
  storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN,
});

export const shopifyApolloClient = new ApolloClient({
  link: createUploadLink({
    uri: `https://${process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN}/api/2023-01/graphql.json`,
    headers: {
      "X-Shopify-Storefront-Access-Token": process.env.NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN,
      Accept: "application/json",
    },
  }),
  ssrMode: true,
  cache: new InMemoryCache(),
});

export default client;
