interface NavigatorExperimental extends Navigator {
  globalPrivacyControl: boolean | undefined;
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/globalPrivacyControl
 *
 * Function to return the browser's protection state
 * @return {boolean} Whether the browser has protection turned on
 */
export const isBrowserProtected = () => {
  return (navigator as NavigatorExperimental)?.globalPrivacyControl;
};
